
import { executePrinting } from "@/api/printapi"
import {BASE_URL} from "@/config/config";

export function printOrder(id) {
  fn(5, id);
}
function fn(printNumber, id) {
  if (printNumber <= 1) {
  //  console.log('打印错误');
    return;
  }

  let printParams = {
    printType: 1,
    printerName: "POS58",
    dataUrl: BASE_URL + "/print/store/receipt/" + id,
    template: null,
    notifyUrl: BASE_URL + "/print/notify/" + 1 + "/" + id,
  };
  executePrinting(printParams).then((response) => {
    if (response === 'SUCCESS') {
      // console.log('打印程序链接成功');
    } else {
      setTimeout(() => { 
        fn(printNumber - 1, id);
      }, 1000);
    }
  }).catch((err)=>{
    // console.log(err);
    setTimeout(() => {
      fn(printNumber - 1, id);
    }, 1000);
  });
}