<template>
  <div>
    <button @click="sendMessage1">发送MQ消息1</button>
  </div>
</template>

<script>
import {senderMessage1} from"@/api/index"

export default {
  name: "TestHttp",
  methods:{
    sendMessage1() {
      senderMessage1(null).then( res => {
        console.log(res.data)
      }).catch( err => {
        return Promise.reject(err)
      });
    }
  }
}
</script>

<style scoped>

</style>