import server from "../utils/http.js";

// 更新当日统计数据
export const updateStatisticsData = () => server.get("/store/statistics/update");

// 获取门店销售概况
export const getInfoList = (params) =>server.post("/store/statistics/sales/volume/info",params);
// 获取门店销量概况
export const getTopsList = (params) =>server.post("/store/statistics/sales/volume/tops",params);
// 获取门店门商品销额排行TOP5
export const getRevenueList = (params) =>server.post("/store/statistics/sales/revenue/tops",params);
// 获取门店支付方式占比
export const getPayList = (params) =>server.post("/store/statistics/sales/payment/method/proportions",params);
// 门店商品销量占比
export const getProportions = (params) =>server.post("/store/statistics/sales/category/proportions",params);
