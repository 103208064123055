<template>
  <div>
    <!-- 搜索 -->
    <div class="head">
      <div>
        <el-form :model="query"  label-width="80px" class="elForm">
        <el-form-item label="分类编号" prop="categoryCode">
          <el-select v-model="query.categoryCode"  @change="change" placeholder="请选择商品分类">
          <el-option
            v-for="item in categorys"
            :key="item.categoryCode"
            :label="item.categoryName"
            :value="item.categoryCode">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="关键字" prop="searchKey">
        <el-input
          style="width: 200px;"
          v-model="query.searchKey"
          placeholder="请输入商品编号/商品名称等搜索"
        />
      </el-form-item>
      <el-form-item style="margin-left: -60px;">
        <el-button type="warning"  size="medium"  @click="search">搜索</el-button>
        <el-button icon="el-icon-refresh" size="medium" @click="clear">重置</el-button>
      </el-form-item>
      </el-form>
      </div>
      <div class="lead">
        <el-button type="danger"  icon="el-icon-download" size="medium" @click="lead">导入菜品</el-button>
      <!-- <el-form-item> -->
      <!-- </el-form-item> -->
      </div>
    </div>
    <el-card class="card">
      <!-- 表格 -->
      <el-table  height="500" :data="list" stripe style="width: 100%">
        <el-table-column fixed label="序号" width="48px" align="center">
          <template v-slot="{ $index }">
            {{ (query.pageNum - 1) * query.pageSize + ($index + 1) }}
          </template>
        </el-table-column>
        <el-table-column label="图片"  align="center" prop="productPic">
          <template v-slot="{row}">
            <el-image style="width: 80px; height: 80px" :src="apiUrl+row.productPic"></el-image>
          </template>
      </el-table-column>
        <el-table-column label="产品名称" width="100px" align="center" prop="productName" />
        <!-- <el-table-column label="商品编号" width="150px" align="center" prop="productCode" /> -->
        <el-table-column label="分类名称"  align="center" prop="categoryName" />
        <el-table-column label="价格" width="150px" align="center" prop="price">
          <template v-slot="{row}">
            <el-input  v-model="row.edit.price" v-if="row.isEdit" placeholder="请输入单价" style="width: 120px;"></el-input>
            <span v-else>￥{{ row.price | toFixed2Filter}}</span>
          </template>
      </el-table-column>
      <el-table-column label="状态" prop="offlineStatus" width="130px" align="center">
        <template v-slot="{row}">
          <el-switch 
          v-if="row.isEdit"
           v-model="row.edit.offlineStatus"
           :active-value="0"
           :inactive-value="1"
           ></el-switch>
        <span v-else>{{ row.offlineStatus==0 ? '上架' : '下架' }}</span>
      </template>
      </el-table-column>
      <el-table-column label="库存" width="130px" align="center" prop="inventoryQuantity">
          <template v-slot="{row}">
            <el-input  onkeyup="value=value.replace(/[^\d]/g,0)" v-model="row.edit.inventoryQuantity" v-if="row.isEdit" placeholder="请输入库存" style="width: 100px;"></el-input>
            <span v-else>{{ row.inventoryQuantity }}</span>
          </template>
      </el-table-column>
      <el-table-column label="操作" width="200" align="center">
          <template  v-slot="{ row }">
            <template v-if="row.isEdit">
              <el-button type="warning"  class="btn" @click="determine(row)">确定</el-button>
              <el-button type="info" class="btn" @click="backtrack(row)">取消</el-button>
            </template>
            <template v-else>
              <el-button type="text" class="btn" @click="edit(row)">修改</el-button>
              <el-button type="text" class="btn" @click="update(row)">更新</el-button>
            </template>
          </template>
      </el-table-column>
      </el-table>
      <!-- 分页器 -->
      <div class="pagination" v-show="total>query.pageSize">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[5, 10, 15, 20]"
          layout="total, sizes, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>
    <!-- 修改对话框 -->
    <el-dialog title="修改商品" size="40%" :visible="open" :show-close="false">
       <el-form ref="form" label-position="left" style="margin-left: 15px;" :model="form" :rules="rules" hide-required-asterisk  label-width="100px">
        <el-row style="margin: 0px;">
            <el-col :span="12">
              <el-form-item label="商品名称:">
                <span>{{ form.productName }}</span>
                <!-- <el-input v-model="form.productName" placeholder="请输入商品名称" /> -->
              </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="单价:" prop="price">
                  <el-input ref="price" style="width: 180px;" v-model.number="form.price" placeholder="请输入商品单价" />
                </el-form-item>
              </el-col>
          </el-row>
          <el-row >
            <el-col :span="12">
              <el-form-item label="商品分类" prop="categoryName">
              <span style="margin: 0px;">{{ form.categoryName }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="库存数量:" prop="inventoryQuantity">
                <el-input style="width: 180px;" ref="inventoryQuantity" v-model.number="form.inventoryQuantity" placeholder="请输入商品库存数量" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin: 0px;">
            <el-col :span="12">
              <el-form-item label="状态" prop="offlineStatus">
                <el-radio-group v-model="form.offlineStatus">
                  <el-radio :label="0">上架</el-radio>
                  <el-radio :label="1">下架</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin: 0px;" v-if="productSkuList.length > 0">
            <el-col :span="24">
              <el-form-item label="规格">
                <el-table
                  :lock-scroll="false"  
                  :append-to-body="true"
                  :data="productSkuList"
                  style="width:800px"
                  class="sku-list">
                  <el-table-column
                    prop="skuCode"
                    label="编号"
                    width="50">
                  </el-table-column>
                  <el-table-column
                    prop="skuName"
                    label="名称" align="center" width="200">
                  </el-table-column>
                  <el-table-column
                    prop="price"
                    label="单价"  align="center">
                    <template v-slot:default="scope">
                      <el-input  v-model="scope.row.price" placeholder="请输入单价(必须)" class="table-cell-input"/>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="inventoryQuantity"
                    label="库存"  align="center">
                    <template v-slot:default="scope">
                      <el-input v-model="scope.row.inventoryQuantity" placeholder="输入库存(必须)" class="table-cell-input"/>
                    </template>
                  </el-table-column>
                  <el-table-column label="操作"  align="center">
                    <template v-slot="{row}">
                      <el-button size="mini" type="text" icon="el-icon-delete" @click="skuDelete(row)">删除</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </el-form-item>
            </el-col>
          </el-row>
       </el-form>
       <div style="height: 10%; margin-top: 10px; border-top: #bfbfbf 1px solid; overflow: hidden">
          <div style="padding-top: 20px; text-align: center;">
            <el-button type="warning" @click="submitForm">确 定</el-button>
            <el-button @click="cancel">取 消</el-button>
          </div>
        </div>
    </el-dialog>
    <!-- 导入对话框 -->
       <el-dialog title="导入菜品 " :show-close="false" width="80%" :visible="isOpen">
         <el-form :model="leadParams"  size="small" :inline="true" label-width="68px">
          <el-form-item label="分类编号" prop="categoryCode">
            <el-select v-model="leadParams.categoryCode" placeholder="请选择商品分类">
              <el-option
                v-for="item in categorys"
                :key="item.categoryCode"
                :label="item.categoryName"
                :value="item.categoryCode">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商品名称" prop="spuName">
            <el-input
              v-model="leadParams.spuName"
              placeholder="请输入商品名称"
              clearable/>
          </el-form-item>
          <el-form-item >
              <el-button type="warning" icon="el-icon-search"  @click="leadRetrieval">检索</el-button>
            <el-button icon="el-icon-refresh"  @click="reset">重置</el-button>
          </el-form-item>
          </el-form>
          <el-table :data="leadList" @selection-change="handleAffirmChange">
            <el-table-column type="selection" width="55" align="center" />
            <el-table-column label="ID" align="center" prop="id" />
            <el-table-column label="商品编号" align="center" prop="spuCode" />
            <el-table-column label="荤素面标识" align="center" prop="meatNoodlesMark">
              <template v-slot="{row}">
                <span v-if="row.meatNoodlesMark==0">其它</span>
                <span v-if="row.meatNoodlesMark==1">荤面</span>
                <span v-if="row.meatNoodlesMark==2">素面</span>
              </template>
            </el-table-column>
            <el-table-column label="商品名称" align="center" prop="spuName" />
            <el-table-column label="单价" align="center" prop="price">
              <template v-slot="{row}">
                ￥{{ row.price | toFixed2Filter }}
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            v-show="leadTotal>leadParams.pageSize"
            :total="leadTotal"
            :page.sync="leadParams.pageNum"
            :limit.sync="leadParams.pageSize"
            @pagination="leadRetrieval"
            class="pagination"/>
          <div slot="footer">
            <el-button type="warning" @click="affirmLead" :disabled="disabled">确 定</el-button>
            <el-button @click="cancelLead">取 消</el-button>
          </div>
        </el-dialog>
    </div>
</template>

<script>
import cache from "@/utils/cache";
import { BASE_URL } from "@/config/config";
import {getGoodsSpuList,
  getGoods,
  getAllList,
  getAllCategory,
  updateGoods,
  skuDel,goodsAsync,
  getLeadCommodity,
  affirmLead} from "@/api/adout";
export default {
  name: "About",
  data(){
    return {
      storeInfo: cache.session.getJSON("storeInfo") || {},
      query:{
       storeId:0,
       categoryCode:'',
       searchKey:'',
       pageNum:1,
       pageSize:10
      },
    // 导入查询参数
      leadParams: {
        pageNum: 1,
        pageSize: 10,
        storeId: null,
        categoryCode : null,
        spuCode: null,
        spuName: null,
      },
      open:false,
      isOpen:false,
      total:0,
      currentPage4:1,
      list:[],
      form:{},
      apiUrl: BASE_URL,
      direction: 'rtl',
      stores:[],
      categorys:[],
      productSkuList:[],
      inputValue: '',
      inputVisible: false,
      productTags: [],
      // 修改校验规则
      rules: {
        price: [
          { required: true, message: "单价不能为空", trigger: "blur" }
        ],
        inventoryQuantity: [
          { required: true, message: "库存数量不能为空", trigger: "blur" }
        ],
      },
      leadList:[],
      leadTotal:0,
      ids:[],
      disabled:false,
    }
  },
  created() {
    this.getList()
    this.getStoreList()
    this.getAllCategory()
  },
  mounted() {
  this.noBackward();
  },
  methods:{
  async  determine(row){
      if (row.edit.price>=0 && row.edit.inventoryQuantity>=0) {
        const res = await updateGoods({
          ...row.edit,
          id:row.id
        })
        if (res.code == 200) {
          this.$message.success('修改成功')
          row.isEdit = false
          row.price = row.edit.price
          row.inventoryQuantity = row.edit.inventoryQuantity
          row.offlineStatus = row.edit.offlineStatus
        }else{
          this.$message.error('网络错误,请稍后重试')
        }
      }else{
        this.$message.error('请输入价格和库存')
      }
    },
    backtrack(row){
     row.edit.inventoryQuantity = row.inventoryQuantity
     row.edit.price = row.price
     row.edit.offlineStatus = row.offlineStatus
      row.isEdit = false
    },
    // 更新
   async update(row){
      const res = await goodsAsync(row.id)
      // console.log(res);
      if (res.code ==200 ) {
        this.$message.success('更新成功')
        this.getList()
      }
    },
    // 导入菜品
    lead(){
      // console.log(123);
      this.isOpen = true
      this.leadRetrieval()
    },
    // 所属门店
    async getStoreList(){
     const res = await getAllList()
        // console.log(response);
        this.stores = res.rows;
    },
    // 分类
    async getAllCategory(){
      const res = await getAllCategory()
      // console.log(res)
      if (res.code == 200) {
        this.categorys = res.rows
      }
    },
    noBackward() {
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate",function(e) {
        history.pushState(null, null, document.URL);
      }, false);
    },
    async getList(){
    this.query.storeId = this.storeInfo.id
      const res = await getGoodsSpuList(this.query)
      // console.log(res);
      if (res.code == 200) {
        this.list = res.rows
        this.total = res.total
        this.list.forEach(item =>{
          // item.isEdit = false
          this.$set(item,'isEdit',false)
          this.$set(item,'edit',{
            price:item.price,
            inventoryQuantity:item.inventoryQuantity,
            offlineStatus:item.offlineStatus
          })
        })
        // console.log(this.list);
      }
    },
    handleSizeChange(val){
      this.query.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val){
      this.query.pageNum = val;
      this.getList();
    },
    change(e){
      // console.log(e); 
      this.query.categoryCode = e
      this.getList()
    },
    search(){
      this.getList()
    },
    clear(){
      this.query={
       storeId:0,
       categoryCode:'',
       searchKey:'',
       pageNum:1,
       pageSize:5
      }
      this.query.storeId = this.storeInfo.id
      this.getList()
    },
    async edit(row){
    // console.log(row.skuSum);
    if (row.skuSum >=1) {
      const res = await getGoods(row.id)
      // console.log(res.data);
        this.form = res.data
        this.productSkuList = res.data.productSkuList;
        this.productTags = JSON.parse(res.data.productTags);
        this.open = true
    }else{
      row.isEdit = true
    }
    },
    submitForm(){
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.form.productSkuList = this.productSkuList;
          this.form.productTags = JSON.stringify(this.productTags);
            updateGoods(this.form).then(response => {
              this.$message.success("修改成功");
              this.open = false;
              this.getList();
            });
          }
        });
    },
    cancel(){
      this.form = {};
      this.open = false
    },
    skuDelete(row){
      this.$confirm('您确定要删除嘛, 是否继续?', '提示',  {
          confirmButtonText:'确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async( ) => {
          const res = await skuDel(row.id)
          // console.log(res);
          if (res.code == 200) {
            let index = this.productSkuList.findIndex(item=>{
              return item.id == row.id       
            })
            // console.log(index)
            this.productSkuList.splice(index,1)
            // console.log(this.productSkuList);
            this.$message.success('删除成功');
          }
        }).catch(() => {});
    },
     // 导入检索
    async  leadRetrieval(){
      this.leadParams.storeId = this.storeInfo.id
      const res = await getLeadCommodity(this.leadParams)
        // console.log(res);
      if (res.code ==200) {
        this.leadList = res.rows
        this.leadTotal = res.total
        if (res.rows.length <=0) {
          this.$message.info('暂无可导入的商品')
        }
      }else{
        this.$message.error('网络错误，请稍后重试')
      }
    },
   async affirmLead(){
      const res = await affirmLead({
      ids:this.ids,
      storeId:this.leadParams.storeId
    })
    this.disabled = true
    // console.log(res);
    if (res.code ==200) {
      this.disabled = false
      this.ids=[]
      this.isOpen = false
      this.$message.success('导入成功')
      this.getList()
    }else{
      this.$message.error('网络错误，请稍后重试')
    }
  },
    cancelLead(){
      this.$confirm('是否关闭页面', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
      this.isOpen = false;
      this.resetLead()
      // console.log(this.leadList);
       }).catch((error) => {
    // 在这里处理异常情况，例如记录错误信息
    console.error('发生错误：', error);
  });
    },
      // 选择导入
    handleAffirmChange(selection){
      this.ids = selection.map(item => item.id)
      // console.log(this.ids);
    },
    resetLead(){
      this.leadParams={
        storeId:null,
        categoryCode:null,
        spuCode:null,
        spuName:null,
      }
      this.leadList = []
    },
    reset(){
      this.leadParams={
        categoryCode:null,
        spuCode:null,
        spuName:null,
      },
      this.leadRetrieval()
    }
  },
  watch:{
    productSkuList:{
      handler(val){
        //计算库存
        let inventoryQuantity = 0;
        val.map(function (sku){
          if(sku.inventoryQuantity > 0){
            inventoryQuantity += Number(sku.inventoryQuantity);
          }
        });
        this.form.inventoryQuantity = Number(inventoryQuantity);
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.head{
  display: flex;
  align-items: center;  
  justify-content: space-between;
}
.elForm{
  display: flex;
}
.card{
  height: 40%;
  overflow: scroll;
}
.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: end;
  margin-right: 50px;
}
.btn{
  font-size: 18px
}
::v-deep .el-table__body-wrapper::-webkit-scrollbar{ 
  width: 0; 
  display: none;
}
::v-deep .card::-webkit-scrollbar{ 
  width: 0; 
  display: none
}
.input-new-tag {
  width: 90px;
  vertical-align: bottom;
}
.button-new-tag {
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: 15px;
}
.lead{
  display: flex;
  align-items: center;
  margin-top: -1%;
}
.table-cell-input{
  width: 80px;
}
</style>