<template>
  <el-row class="main-el-row">
    <el-col :span="7" class="left-view">
      <div class="shopping-cart-left-main">
        <el-menu mode="horizontal">
          <el-menu-item>
            <div @click="back">
              <el-button class="back" type="danger" size="medium" round
                >返回</el-button
              >
            </div>
            {{ showNumber }}
          </el-menu-item>
          <el-menu-item class="top-menu-right">
            <el-button
              type="warning"
              size="medium"
              @click="clearCart"
              :disabled="submitDisabled"
              round
              >清空</el-button
            >
          </el-menu-item>
        </el-menu>
        <div class="shopping-cart-center">
          <el-row class="main-el-row">
            <el-col :span="18" class="main-el-row">
              <vue-scroll :ops="ops">
                <div class="shopping-cart-list">
                  <el-table
                    ref="cartTable"
                    :data="cartList"
                    highlight-current-row
                    :row-class-name="tableRowClassName"
                    @row-click="onRowClick"
                  >
                    <el-table-column prop="product" label="菜品">
                      <template v-slot:default="scope">
                        {{ scope.row.spuName }}<br />
                        <span v-if="scope.row.skuName">
                          {{ scope.row.skuName }}<br />
                        </span>
                        <span v-if="scope.row.remark">
                          备注：{{ scope.row.remark }}<br />
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="quantity"
                      label="数量"
                      width="50"
                    ></el-table-column>
                    <el-table-column
                      prop="subtotalAmount"
                      label="小计"
                      width="80"
                      align="right"
                    >
                      <template v-slot:default="scope">
                        ￥{{ scope.row.subtotalAmount | toFixed2Filter }}
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </vue-scroll>
            </el-col>
            <el-col :span="6" class="cart-caozuo-menu">
              <el-button
                type="info"
                @click="addOrReduceQuantity('add')"
                plain
                icon="el-icon-plus"
                :disabled="submitDisabled"
              ></el-button>
              <!-- 键盘键入值 -->
              <el-input
                v-model="cartNumper"
                @keyup.native="editReduceQuantity($event)"
                @blur="keyDow"
                :disabled="submitDisabled"
              ></el-input>
              <el-button
                type="info"
                @click="addOrReduceQuantity('reduce')"
                plain
                icon="el-icon-minus"
                :disabled="submitDisabled"
              ></el-button>
              <el-popover
                v-if="this.currentRow"
                ref="remarkPop"
                placement="right"
                width="380"
                trigger="click"
              >
                <div>
                  <el-input
                    v-model="productRemarkValue"
                    ref="productRemark"
                    maxlength="20"
                    @blur="remarkBut(false)"
                    clearable
                    @clear="productRemarkClear"
                    show-word-limit
                    placeholder="请输入备注内容"
                  >
                    <el-button
                      slot="append"
                      icon="el-icon-check"
                      @click="remarkBut(true)"
                    ></el-button>
                  </el-input>
                </div>
                <div style="padding-top: 10px">
                  <el-tag
                    v-for="tagName in remarkProductTags"
                    @click="tagClick(tagName)"
                    :key="tagName"
                    type="warning"
                  >
                    {{ tagName }}
                  </el-tag>
                </div>
                <el-button
                  type="info"
                  slot="reference"
                  @click="showProductRemarkBut"
                  plain
                  >备注</el-button
                >
              </el-popover>
              <el-button v-else type="info" disabled plain>备注</el-button>
              <el-button
                type="info"
                plain
                @click="deleteCartProduct"
                :disabled="submitDisabled"
                >删除</el-button
              >
            </el-col>
          </el-row>
        </div>
        <div class="shopping-cart-bottom">
          <div class="order-remarks" v-show="orderRemarkVisible">
            <div style="padding: 20px">
              <el-input
                v-model="orderRemarkValue"
                ref="orderRemarkValue"
                maxlength="20"
                show-word-limit
                @blur="submitRemark"
                clearable
                @clear="orderRemarkClear"
                placeholder="请输入备注内容"
              >
                <el-button
                  slot="append"
                  icon="el-icon-check"
                  @click="submitRemark"
                ></el-button>
              </el-input>
            </div>
          </div>
          <div class="order-info">
            <el-row class="top-view">
              <el-col :span="8">
                <el-button
                  type="info"
                  size="small"
                  @click="visibleBut"
                  :disabled="submitDisabled"
                  icon="el-icon-edit-outline"
                  plain
                  >备注</el-button
                >
              </el-col>
              <el-col :span="16">
                <div class="order-total-amount">
                  总计金额：￥{{ this.totalAmount | toFixed2Filter }}
                </div>
                <div
                  style="
                    text-align: right;
                    font-size: 12px;
                    color: #bfbfbf;
                    line-height: 24px;
                  "
                >
                  备注：{{
                    this.orderInfo.remark ? this.orderInfo.remark : "无"
                  }}
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="end-view">
            <div style="padding: 20px">
              <el-button
                type="danger"
                class="submit-order-but"
                :disabled="submitDisabled"
                @click="submitOrder"
                round
                >确认下单</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </el-col>
    <el-col :span="17" class="right-view">
      <div class="right-view-top">
        <el-menu mode="horizontal">
          <el-menu-item>
            <el-input
              placeholder="输入菜品名称/首字母"
              clearable
              maxlength="20"
              @clear="searchClear"
              v-model="searchKey"
              class="search-input"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="searchProduct"
              ></el-button>
            </el-input>
          </el-menu-item>
          <el-menu-item class="top-menu-right">
            <el-button @click="repairPrint" type="danger" size="medium" round
              >补打小票</el-button
            >
            <el-button
              @click="openWriteOffView"
              type="danger"
              size="medium"
              round
              >人工核销</el-button
            >
          </el-menu-item>
        </el-menu>
      </div>
      <div class="right-view-bottom">
        <vue-scroll :ops="ops" class="product-scroll" v-if="searchVisible">
          <div class="product-scroll-main">
            <el-row :gutter="24">
              <el-col
                :span="6"
                v-for="(product, index) in searchProducts"
                :key="index"
              >
                <div class="product-item" @click="addCart(product)">
                  <el-badge
                    :value="product.quantity"
                    v-show="product.quantity"
                    class="item badge"
                  ></el-badge>
                  <el-row :gutter="10" class="product-item-main">
                    <!-- <el-col :span="10">
                      <el-image :src="apiUrl + product.productPic"></el-image>
                    </el-col> -->
                    <el-col :span="24">
                      <span class="title">{{ product.spuName }}</span
                      ><br />
                      <span class="remarks">{{ product.skuName }}</span
                      ><br />
                      <span class="price"
                        >￥{{ product.price | toFixed2Filter }}</span
                      >
                      <br />
                    </el-col>
                  </el-row>
                </div>
              </el-col>
            </el-row>
          </div>
        </vue-scroll>
        <el-tabs tab-position="right" v-else>
          <el-tab-pane
            :label="category.categoryName"
            :key="category.categoryId"
            v-for="category in products"
          >
            <vue-scroll :ops="ops" class="product-scroll">
              <!-- 菜单选项 -->
              <div class="product-scroll-main">
                <el-row :gutter="10">
                  <el-col
                    :span="6"
                    v-for="(product, productIndex) in category.products"
                    :key="productIndex"
                  >
                    <div
                      :class="
                        product.inventoryQuantity == 0
                          ? 'product-itemBgc'
                          : 'product-item'
                      "
                      @click="addCart(product)"
                    >
                      <el-badge
                        :value="product.quantity"
                        v-show="product.quantity"
                        class="item badge"
                      ></el-badge>
                      <el-row :gutter="10" class="product-item-main">
                        <!-- <el-col :span="8">
                          <el-image
                            :src="apiUrl + product.productPic"
                            @error="handleImageError"
                          >
                          </el-image>
                        </el-col> -->
                        <el-col :span="24">
                          <span class="title">{{ product.spuName }}</span
                          ><br />
                          <span class="remarks">{{ product.skuName }}</span>
                          <br />
                          <span class="price"
                            >￥{{ product.price | toFixed2Filter }}起</span
                          ><br />
                        </el-col>
                        <div
                          v-show="product.inventoryQuantity <= 0"
                          class="sellout"
                        >
                          已售罄
                        </div>
                      </el-row>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </vue-scroll>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-col>
    <!-- 支付 -->
    <el-dialog
      width="90%"
      append-to-body
      fullscreen
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :destroy-on-close="true"
      :show-close="false"
    >
      <div class="paymentProcedure" v-if="operateStatus == 1">
        <div class="paymentPage">
          <div class="payLeftBut">
            <h2 style="color: #e99d42; margin: 3%">
              {{ showNumber }}
            </h2>
            <div class="shopping-cart-list">
              <el-table ref="cartTable" :data="orderDetail" max-height="600px">
                <el-table-column prop="product" label="菜品">
                  <template v-slot:default="scope">
                    {{ scope.row.productName }}<br />
                    <span v-if="scope.row.skuName">
                      {{ scope.row.skuName }}<br />
                    </span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="quantity"
                  label="数量"
                  width="50"
                ></el-table-column>
                <el-table-column prop="price" label="市场价" align="center">
                  <template v-slot="{ row }">
                    ￥{{ row.price | toFixed2Filter }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="discountAmount"
                  label="优惠金额"
                  align="center"
                >
                  <template v-slot="{ row }">
                    ￥{{ row.discountAmount | toFixed2Filter }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="paymentAmount"
                  label="应付金额"
                  width="80"
                  align="right"
                >
                  <template v-slot:default="scope">
                    ￥{{ scope.row.paymentAmount | toFixed2Filter }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="discountsInfo">
              <div style="margin: 3%">
                共<span class="word">{{ discounts.totalQuantity }}</span
                >件商品
              </div>
              <div>
                <div class="amount">
                  应付金额:
                  <span class="word"
                    >￥{{ discounts.paymentAmount | toFixed2Filter }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="dialog-content">
          <div class="headInfo">
            <div class="search">
              <div class="phone">
                <el-input
                  v-model="memberPhoneNumber"
                  placeholder="请输入客户手机号"
                  maxlength="11"
                  style="width: 95%"
                  type="number"
                  class="no-number"
                  @focus="inputFocus"
                  @clear="clearChange"
                  @input="clearChange"
                  prefix-icon="el-icon-phone"
                />
                <div class="changePhone">
                  <el-button type="warning" @click="changePhone"
                    >更换用户</el-button
                  >
                </div>
              </div>
              <div class="memberInfo">
                <div class="userInfo">
                  <i style="font-size: 50px" class="el-icon-user"></i>
                  <div style="margin-left: 20px; width: 150px">
                    <div v-if="userInfo.contacts">
                      {{ userInfo.contacts }}({{ userInfo.phoneNumber }})
                    </div>
                    <div v-if="userInfo.memberLevel == null">堂食散客</div>
                    <div>{{ userInfo.memberLevel || "LV.00" }}</div>
                  </div>
                </div>
                <div class="orderInfo">
                  <div>可用余额</div>
                  <div class="text">￥{{ userInfo.balances || 0 }}</div>
                </div>
                <div class="orderInfo">
                  <div>可用积分</div>
                  <div class="text">{{ userInfo.integral || 0 }}</div>
                </div>
                <div>
                  <div>积分优惠</div>
                  <el-switch
                    :value="params.pointDeduction"
                    active-color="#e99d42"
                    @change="pointDeductionChange"
                  />
                </div>
              </div>
            </div>
            <div class="payment">
              <div class="paymentLeft">
                <div
                  class="paymentBox"
                  @click="btnClick('CASH_PAYMENT')"
                  :class="{ active: btnAction == 'CASH_PAYMENT' }"
                >
                  <i class="icon-xj iconfont" />
                  <span style="font-size: 20px">现金</span>
                  <i
                    class="el-icon-check iconRight"
                    v-if="btnAction == 'CASH_PAYMENT'"
                  />
                </div>
                <div
                  class="paymentBox"
                  @click="btnClick('MICRO_PAYMENT')"
                  :class="{ active: btnAction == 'MICRO_PAYMENT' }"
                >
                  <i class="icon-fkm iconfont" />
                  <span style="font-size: 20px">付款码</span>
                  <i
                    class="el-icon-check iconRight"
                    v-if="btnAction == 'MICRO_PAYMENT'"
                  />
                </div>

                <div
                  v-if="memberPayment"
                  class="paymentBox"
                  @click="btnClick('MEMBER_BALANCE_PAYMENT')"
                >
                  <i class="icon-hy iconfont" />
                  <span style="font-size: 20px">会员</span>
                  <i
                    class="el-icon-check iconRight"
                    v-if="btnAction == 'MEMBER_BALANCE_PAYMENT'"
                  />
                </div>
                <el-tooltip
                  v-else
                  class="item"
                  effect="light"
                  content="只有会员才能使用会员支付、请输入会员手机号"
                  placement="right"
                >
                  <div class="grayBackground">
                    <i class="icon-hy iconfont" />
                    <span style="font-size: 20px">会员</span>
                    <i
                      class="el-icon-check iconRight"
                      v-if="btnAction == 'MEMBER_BALANCE_PAYMENT'"
                    />
                  </div>
                </el-tooltip>
                <div
                  class="paymentBox"
                  @click="btnClick('SCAN_CODE_PAYMENT')"
                  :class="{ active: btnAction == 'SCAN_CODE_PAYMENT' }"
                >
                  <i class="icon-fkm1 iconfont" />
                  <span style="font-size: 20px">扫码</span>
                  <i
                    class="el-icon-check iconRight"
                    v-if="btnAction == 'SCAN_CODE_PAYMENT'"
                  />
                </div>
              </div>
              <div class="paymentRight">
                <div class="paymentTop">
                  <div class="orderInfo">
                    <span class="spanText">订单总金额</span>
                    <span class="text">{{
                      discounts.totalAmount | toFixed2Filter
                    }}</span>
                    <span class="spanText"
                      >共
                      <span class="amountText">{{
                        discounts.totalQuantity
                      }}</span>
                      件商品
                    </span>
                  </div>
                  <el-divider direction="vertical"></el-divider>
                  <div class="orderInfo">
                    <span class="spanText">会员优惠金额</span>
                    <span class="text"
                      >￥{{ discounts.memberDiscountAmount || 0 }}</span
                    >
                    <span class="spanText"
                      >会员折扣:
                      <span class="amountText">{{
                        discounts.memberDiscount + "折" || "无折扣"
                      }}</span>
                    </span>
                  </div>
                  <el-divider direction="vertical"></el-divider>
                  <div class="orderInfo">
                    <span class="spanText">积分抵扣金额</span>
                    <span class="text"
                      >￥{{ discounts.pointsDeductionAmount || 0 }}</span
                    >
                    <span class="spanText"
                      >抵扣积分数:
                      <span class="amountText">{{
                        discounts.deductionPoints || 0
                      }}</span>
                    </span>
                  </div>
                </div>
                <div
                  class="btmBox"
                  v-if="btnAction == 'CASH_PAYMENT' && operateStatus == 1"
                >
                  <div class="paymentBtm">
                    <div class="orderInfo">
                      <div>应收金额</div>
                      <div class="text2">
                        ￥{{ discounts.paymentAmount | toFixed2Filter }}
                      </div>
                    </div>
                    <div class="cal">
                      <span>实收:</span>
                      <span>￥{{ display || 0 | toFixed2Filter }}</span>
                    </div>
                    <div class="cal">
                      <span>找零:</span>
                      <span
                        >￥{{
                          display - discounts.paymentAmount > 0
                            ? display - discounts.paymentAmount
                            : 0 | toFixed2Filter
                        }}</span
                      >
                    </div>
                  </div>
                  <div class="keyboard">
                    <div class="keyboard-item">
                      <el-button @click="calNum(100)" class="frame"
                        >￥100</el-button
                      >
                      <el-button @click="cal(1)" class="frame">1</el-button>
                      <el-button @click="cal(2)" class="frame">2</el-button>
                      <el-button @click="cal(3)" class="frame">3</el-button>
                    </div>
                    <div class="keyboard-item">
                      <el-button @click="calNum(50)" class="frame"
                        >￥50</el-button
                      >
                      <el-button @click="cal(4)" class="frame">4</el-button>
                      <el-button @click="cal(5)" class="frame">5</el-button>
                      <el-button @click="cal(6)" class="frame">6</el-button>
                    </div>
                    <div class="keyboard-item">
                      <el-button @click="calNum(20)" class="frame"
                        >￥20</el-button
                      >
                      <el-button @click="cal(7)" class="frame">7</el-button>
                      <el-button @click="cal(8)" class="frame">8</el-button>
                      <el-button @click="cal(9)" class="frame">9</el-button>
                    </div>
                    <div class="keyboard-item">
                      <el-button @click="calNum(10)" class="frame"
                        >￥10</el-button
                      >
                      <el-button @click="cal(0)" class="frame">0</el-button>
                      <el-button @click="cal('.')" class="frame">.</el-button>
                      <el-button @click="deleteDigit" class="frame"
                        >X</el-button
                      >
                    </div>
                    <div class="keyboard-item">
                      <el-button
                        @click="confirmPayment"
                        class="account"
                        type="warning"
                        >结算</el-button
                      >
                    </div>
                  </div>
                </div>
                <div
                  v-loading="paymentLoading"
                  v-if="btnAction == 'MICRO_PAYMENT'"
                >
                  <div class="payment-result">
                    <el-result
                      v-if="stateOfPayment == 1"
                      :title="
                        '应收金额：￥' +
                        parseFloat(discounts.paymentAmount).toFixed(2)
                      "
                      subTitle="扫描客户出示的付款码进行收款交易"
                    >
                      <template slot="icon">
                        <img src="@/assets/images/scancode-1.png" width="120" />
                      </template>
                    </el-result>
                    <el-result
                      v-if="stateOfPayment == 2"
                      icon="warning"
                      title="订单支付中"
                      subTitle="订单已提交支付,请等待系统返回支付结果"
                    ></el-result>
                    <el-result
                      v-if="stateOfPayment == 3"
                      icon="error"
                      title="订单支付失败"
                      subTitle="订单支付失败,请更换其它支付方式或取消订单重新下单"
                    >
                    </el-result>
                  </div>
                </div>
                <div
                  v-if="
                    btnAction == 'MEMBER_BALANCE_PAYMENT' && operateStatus == 1
                  "
                >
                  <div class="payment-result">
                    <el-result
                      :title="
                        '应收金额：￥' +
                        parseFloat(discounts.paymentAmount).toFixed(2)
                      "
                      subTitle=""
                    >
                      <template slot="icon">
                        <img src="@/assets/images/scancode-0.png" width="120" />
                      </template>
                    </el-result>
                    <el-button
                      class="member"
                      :disabled="isProcessing"
                      @click="confirmPayment"
                      type="warning"
                      >会员支付</el-button
                    >
                  </div>
                </div>
                <div
                  v-if="btnAction == 'SCAN_CODE_PAYMENT' && operateStatus == 1"
                >
                  <div class="payment-result">
                    <el-result
                      :title="
                        '应收金额：￥' +
                        parseFloat(discounts.paymentAmount).toFixed(2)
                      "
                      subTitle=""
                    >
                      <template slot="icon">
                        <img src="@/assets/images/scancode.png" width="120" />
                      </template>
                    </el-result>
                    <el-button
                      class="member"
                      :disabled="isScan"
                      @click="confirmPayment"
                      type="success"
                      >确认支付</el-button
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="operation">
              <div
                class="funBut"
                @click="cancelOrder"
                v-show="operateStatus == 1 || operateStatus == 3"
              >
                <i class="el-icon-document" />
                <span>取消</span>
              </div>
              <div
                class="funBut"
                @click="editOrder"
                v-show="operateStatus == 1"
              >
                <i class="el-icon-edit" />
                <span>修改</span>
              </div>
              <div class="funBut" @click="deleteOrder">
                <i class="el-icon-error"></i>
                <span>退出</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <div>
      <repairPrint ref="repairPrint"></repairPrint>
      <manualVerifiCation ref="manualVerifiCation"></manualVerifiCation>
    </div>
    <div class="audio">
      <audio ref="audioPlayer" :src="mp3" controls></audio>
    </div>
  </el-row>
</template>
<script>
import repairPrint from "@/components/repairPrint.vue";
import { printOrder } from "@/router/paymentsucce.js";
import {
  getProducts,
  productSearch,
  getOrderNumber,
  addOrder,
  cancelOrder,
  checkOrderEdit,
  storePayment,
  getDiscounts,
  getPhoneNumber,
  getPaymentStatus,
} from "@/api/index";
import cache from "@/utils/cache";
import vueScroll from "vuescroll";
import { BASE_URL } from "@/config/config";
import { getInventory } from "@/api/index.js";
import { playSpeech } from "@/utils/speech";
import manualVerifiCation from "@/components/manualVerifiCation.vue";
export default {
  components: {
    vueScroll,
    repairPrint,
    manualVerifiCation,
  },
  name: "ShoppingCart",
  data() {
    return {
      stateOfPayment: 1,
      btnAction: "CASH_PAYMENT",
      query: {
        pageNum: 1,
        pageSize: 10,
      },
      storeid: 0,
      searchValue: "",
      isOpen: true,
      apiUrl: BASE_URL,
      storeInfo: {},
      products: [],
      cartList: [],
      totalAmount: 0,
      searchKey: "",
      searchVisible: false,
      cartNumper: 1,
      currentRow: null,
      remarkProductTags: [],
      productRemarkValue: "",
      orderRemarkVisible: false,
      orderRemarkValue: "",
      orderInfo: {},
      foodOrder: {},
      searchProducts: [],
      submitDisabled: true,
      orderNumber: {},
      showNumber: "",
      serialPort: null,
      dialogFormVisible: false,
      operateStatus: 1,
      paymentMethod: "CASH_PAYMENT",
      barcodeValue: null,
      code: "", // 支付码
      lastTime: null,
      scannerStopper: null,
      paymentLoading: false,
      confirmDialog: false,
      ops: {
        container: ".product-scroll",
        wheel: true,
        vuescroll: {},
        scrollPanel: {
          initialScrollY: 0, //只要组件mounted之后自动滚动的距离。 例如 100 or 10%
          initialScrollX: 0,
          scrollingX: false,
          scrollingY: true,
        },
        rail: {
          keepShow: true,
        },
        bar: {
          hoverStyle: true,
          onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
          background: "#cdb684", //滚动条颜色
          opacity: 0, //滚动条透明度
          "overflow-x": "hidden",
        },
      },
      inventory: 0,
      //查询会员优惠
      params: {
        orderId: "", //	订单ID，非空必须大于0的数字类型
        customId: 0, //	客户ID，非空，0为不享受优惠
        pointDeduction: false, //是否使用积分优惠（当pointDeduction为true时customId必须大于0）
        memberDiscounts: false, //是否享受会员折扣（当memberDiscounts为true时customId必须大于0）
      },
      memberPhoneNumber: "", //会员手机号
      discounts: {},
      orderDetail: [],
      form: {
        sex: "0", //性别
        phoneNumber: "", //注册手机号码（必填，校验手机号码格式）
        birthday: new Date("1990-01-01"),
        contacts: "", //会员联系人（必填）
        channelUserId: "0",
      },
      display: "0",
      currentNumber: "",
      userInfo: {},
      network: true, //默认有网
      disabled: false,
      timeoutId: null,
      memberPayment: false,
      mp3: "",
      isProcessing: false,
      isScan: false,
      type: "",
    };
  },
  created() {
    this.getStoreInfo();
  },
  mounted() {
    this.noBackward();
    this.openSerialPort();
    this.type = this.$route.query.value;
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleBarcodeScan);
  },
  destroyed() {
    this.writerData(0.0);
    setTimeout(() => {
      this.serialPort?.close();
    }, 0);
  },
  methods: {
    back(){
      if (!this.type) {
         this.$router.push('/home');
      }else{
         this.$router.push('/customerOrder');
      }
    },
    // 打开补打小票
    repairPrint() {
      this.$refs.repairPrint.openModal();
    },
    async getSpeechList(speechCode) {
      await playSpeech(speechCode, this.apiUrl, this.$data);
       this.isProcessing = false;
        this.isScan = false;
    },
    btnClick(value) {
      if (this.userInfo.customType != 2 && value == "MEMBER_BALANCE_PAYMENT") {
        return;
      } else {
        if (value != "CASH_PAYMENT") {
          this.btnAction = value;
          this.paymentMethod = value;
          this.display = 0;
        } else {
          this.btnAction = value;
          this.paymentMethod = value;
        }
        (this.display = "0"), (this.currentNumber = "");
      }
    },
    noBackward() {
      history.pushState(null, null, document.URL);
      window.addEventListener(
        "popstate",
        function (e) {
          history.pushState(null, null, document.URL);
        },
        false
      );
    },
    async openSerialPort() {
      if ("serial" in navigator) {
        await navigator.serial.getPorts().then((ports) => {
          if (
            ports !== null &&
            ports !== "" &&
            Array.isArray(ports) &&
            ports.length > 0
          ) {
            this.serialPort = ports[0];
          } else {
            this.serialPort = null;
          }
        });
        if (!this.serialPort) {
          await navigator.serial.requestPort().then(
            (port) => {
              this.serialPort = port;
            },
            (error) => {
              this.serialPort = null;
            }
          );
        }
        try {
          if (this.serialPort) {
            await this.serialPort.open({
              baudRate: 2400,
              dataBits: 8,
              stopBits: 1,
            });
          } else {
            this.$message.error(
              "无法打开 serialPort: serialPort 为 null 或 undefined"
            );
          }
        } catch (err) {
          console.error(err);
          this.$message.error(
            "无法打开 serialPort: serialPort 为 null 或 undefined"
          );
        }
      }
    },
    async writerData(totalAmount) {
      try {
        //操作客显
        let writer = this.serialPort.writable.getWriter();
        // let data = new Uint8Array([0x0C,0x1B,0x73,0x30]);//清空显示
        // await writer.write(data);
        totalAmount = parseFloat(totalAmount).toFixed(2);
        let data = this.toUint8Arr(totalAmount);
        await writer.write(data);
        writer.releaseLock();
      } catch (error) {
        // this.$message({message:'客显异常',type:'error'})
      }
    },
    toUint8Arr(str) {
      const buffer = [];
      buffer.push(0x0c);
      buffer.push(0x1b);
      buffer.push(0x73);
      buffer.push(0x32);
      buffer.push(0x1b);
      buffer.push(0x51);
      buffer.push(0x41);
      for (let i of str) {
        const _code = i.charCodeAt(0);
        if (_code < 0x80) {
          buffer.push(_code);
        } else if (_code < 0x800) {
          buffer.push(0xc0 + (_code >> 6));
          buffer.push(0x80 + (_code & 0x3f));
        } else if (_code < 0x10000) {
          buffer.push(0xe0 + (_code >> 12));
          buffer.push(0x80 + ((_code >> 6) & 0x3f));
          buffer.push(0x80 + (_code & 0x3f));
        }
      }
      buffer.push(0x0d);
      return Uint8Array.from(buffer);
    },
    getStoreInfo() {
      let storeInfo = cache.session.getJSON("storeInfo");
      if (storeInfo) {
        this.storeInfo = storeInfo;
        this.getOrderNumber();
        this.getProducts();
      } else {
        this.$router.push("/login");
      }
    },
    //计算器
    cal(value) {
      if (value === ".") {
        if (!this.currentNumber.includes(".")) {
          this.currentNumber += value;
        }
      } else {
        this.currentNumber += value;
      }
      this.display = this.currentNumber;
      // console.log("------", this.display);
    },
    calNum(val) {
      this.display = String(val);
    },
    // 计算机删除
    deleteDigit() {
      this.display = this.display.slice(0, -1);
      this.currentNumber = this.currentNumber.slice(0, -1);
      // console.log("++++++++++", this.display);
    },
    // 是否使用积分
    pointDeductionChange() {
      this.params.pointDeduction = !this.params.pointDeduction;
      //  console.log(this.params.pointDeduction);
      this.clearChange();
    },
    inputFocus() {
      this.btnAction = "CASH_PAYMENT";
      this.paymentMethod = "CASH_PAYMENT";
    },
    async clearChange() {
      const phoneRegex = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
      try {
        if (
          this.memberPhoneNumber.length === 11 &&
          phoneRegex.test(this.memberPhoneNumber)
        ) {
          const res = await getPhoneNumber(this.memberPhoneNumber);
          // console.log(res);
          if (res.code === 200) {
            if (res.data && res.data.customType === 2) {
              this.userInfo = res.data;
              this.params.customId = res.data.id;
              this.memberPayment = true;
              this.params.memberDiscounts = true;
              this.getDiscounts();
            } else {
              this.memberPayment = false;
            }
          } else {
            this.memberPayment = false;
            this.$notify.error({
              title: "错误",
              message: res.msg,
              duration: 5000,
            });
          }
        } else {
          this.memberPayment = false;
          this.userInfo = {};
          if (this.memberPhoneNumber.length == 11) {
            this.changePhone();
          }
        }
      } catch (error) {
        this.memberPayment = false;
        this.$notify.error({
          title: "错误",
          message: "网络请求失败",
          duration: 5000,
        });
      }
    },
    getProducts() {
      let query = {
        storeId: this.storeInfo.id,
      };
      getProducts(query).then((response) => {
        if (response.code === 200) {
          this.products = response.rows;
          // TODO: 商品列表
          // console.log(this.products);
        } else {
          this.$message({ message: response.msg, type: "warning" });
        }
      });
    },
    searchProduct() {
      if (this.searchKey && this.searchKey != "") {
        let query = {
          storeId: this.storeInfo.id,
          searchKey: this.searchKey,
        };
        productSearch(query).then((response) => {
          if (response.code === 200) {
            this.searchProducts = response.rows;
            if (this.cartList) {
              this.cartList.forEach((product) => {
                let searchProductIndex = this.searchProducts.findIndex(
                  (item) =>
                    item.spuId == product.spuId && item.skuId == product.skuId
                );
                if (searchProductIndex >= 0) {
                  this.searchProducts[searchProductIndex].quantity =
                    product.quantity;
                }
              });
            }
          } else {
            this.$message({ message: response.msg, type: "warning" });
          }
        });
        this.searchVisible = true;
      } else {
        this.searchProducts = [];
        this.searchVisible = false;
      }
    },
    getOrderNumber() {
      let query = {
        storeId: this.storeInfo.id,
      };
      getOrderNumber(query).then((response) => {
        if (response.code === 200) {
          this.orderNumber = response.data;
          this.isProcessing = false;
          this.isScan = false;
          this.initShowNumber();
        } else {
          this.$notify({
            title: "提示",
            message: response.msg,
            duration: 10000,
            type: "warning",
          });
        }
      });
    },
    initShowNumber() {
      let val = "000000" + this.orderNumber.serialNumber;
      this.showNumber = "NO." + val.substring(val.length - 6, val.length);
    },

    searchClear() {
      this.searchProducts = [];
      this.searchVisible = false;
    },

    async addCart(product) {
      let categoryIndex = this.products.findIndex(
        (item) => item.categoryId == product.categoryId
      );
      let productIndex, allProductIndex, searchProductIndex;
      productIndex = this.products[categoryIndex].products.findIndex(
        (item) => item.spuId == product.spuId && item.skuId == product.skuId
      );

      allProductIndex = this.products[0].products.findIndex(
        (item) => item.spuId == product.spuId && item.skuId == product.skuId
      );

      searchProductIndex = this.searchProducts.findIndex(
        (item) => item.spuId == product.spuId && item.skuId == product.skuId
      );

      let cartSku = this.cartList.find(
        (item) => item.spuId == product.spuId && item.skuId == product.skuId
      );
      let cartSkuIndex = this.cartList.findIndex(
        (item) => item.spuId == product.spuId && item.skuId == product.skuId
      );
      const res = await getInventory({
        spuId: product.spuId,
        skuId: product.skuId,
      });
      this.inventory = res.data;
      if (this.inventory <= 0)
        return this.$message({ message: "库存不足", type: "warning" });

      if (cartSku) {
        if (this.inventory > this.cartNumper) {
          cartSku.quantity = Number(cartSku.quantity) + 1;
          cartSku.subtotalAmount = product.price * cartSku.quantity;
          if (categoryIndex >= 0 && productIndex >= 0) {
            this.products[categoryIndex].products[productIndex].quantity =
              cartSku.quantity;
          }

          if (allProductIndex >= 0) {
            this.products[0].products[allProductIndex].quantity = cartSku.quantity;
          }

          if (searchProductIndex >= 0) {
            this.searchProducts[searchProductIndex].quantity = cartSku.quantity;
          }
          this.cartNumper = cartSku.quantity;
          this.cartList[cartSkuIndex].quantity = this.cartNumper;
          this.cartList[cartSkuIndex].subtotalAmount = cartSku.subtotalAmount;
          this.currentRow = cartSku;
          // console.log(cartSku.quantity);
          product.quantity = cartSku.quantity;
          // console.log(product.quantity);
        } else {
          this.$message({ message: "库存不足", type: "warning" });
        }
      } else {
        product.quantity = 1;
        product.subtotalAmount = product.price;
        this.currentRow = product;
        if (categoryIndex >= 0 && productIndex >= 0) {
          this.products[categoryIndex].products[productIndex].quantity = product.quantity;
        }

        if (allProductIndex >= 0) {
          this.products[0].products[allProductIndex].quantity = product.quantity;
        }

        if (searchProductIndex >= 0) {
          this.searchProducts[searchProductIndex].quantity = product.quantity;
        }
        this.cartNumper = product.quantity;
        this.cartList.push(product);
      }
      this.cartList = JSON.parse(JSON.stringify(this.cartList));
      // console.log(this.cartList);
    },
    addOrReduceQuantity(val) {
      if (this.currentRow) {
        let item = this.cartList.find(
          (item) =>
            item.spuId == this.currentRow.spuId && item.skuId == this.currentRow.skuId
        );
        let index = this.cartList.findIndex(
          (item) =>
            item.spuId == this.currentRow.spuId && item.skuId == this.currentRow.skuId
        );
        if (val == "add") {
          if (this.inventory > this.cartNumper) {
            this.addCart(item);
          } else {
            this.$message({ message: "库存不足", type: "warning" });
          }
        } else if (val == "reduce") {
          if (this.cartNumper > 1) {
            this.cartNumper = Number(this.cartNumper) - 1;
          }
        }
        this.cartList[index].quantity = this.cartNumper;
        this.cartList[index].subtotalAmount =
        this.cartList[index].price * this.cartList[index].quantity;
        let categoryIndex, productIndex, searchProductIndex, allProductIndex;
        categoryIndex = this.products.findIndex(
          (item) => item.categoryId == this.currentRow.categoryId
        );
        productIndex = this.products[categoryIndex].products.findIndex(
          (item) =>
            item.spuId == this.currentRow.spuId &&
            item.skuId == this.currentRow.skuId
        );
        allProductIndex= this.products[0].products.findIndex(
          (item) =>
            item.spuId == this.currentRow.spuId &&
            item.skuId == this.currentRow.skuId
        );
        searchProductIndex = this.searchProducts.findIndex(
          (item) =>
            item.spuId == this.currentRow.spuId && item.skuId == this.currentRow.skuId
        );
        if (categoryIndex >= 0 && productIndex >= 0) {
          this.products[categoryIndex].products[productIndex].quantity =this.cartNumper;
        }
        if (allProductIndex >= 0) {
          this.products[0].products[allProductIndex].quantity =this.cartNumper;
        }
        if (searchProductIndex >= 0) {
          this.searchProducts[searchProductIndex].quantity = this.cartNumper;
        }
        this.cartList = JSON.parse(JSON.stringify(this.cartList));
        this.products = JSON.parse(JSON.stringify(this.products));
      }
    },
    editReduceQuantity() {
      let value = this.cartNumper;
      if (!this.isPositiveInteger(value)) {
        this.cartNumper = 1;
      }
      if (this.currentRow) {
        let index = this.cartList.findIndex(
          (item) =>
            item.spuId == this.currentRow.spuId &&
            item.skuId == this.currentRow.skuId
        );
        if (index >= 0) {
          this.cartList[index].quantity = this.cartNumper;
          this.cartList[index].subtotalAmount =
            this.cartList[index].price * this.cartList[index].quantity;
          let categoryIndex, productIndex, searchProductIndex;
          categoryIndex = this.products.findIndex(
            (item) => item.categoryId == this.currentRow.categoryId
          );
          productIndex = this.products[categoryIndex].products.findIndex(
            (item) =>
              item.spuId == this.currentRow.spuId &&
              item.skuId == this.currentRow.skuId
          );
          searchProductIndex = this.searchProducts.findIndex(
            (item) =>
              item.spuId == this.currentRow.spuId &&
              item.skuId == this.currentRow.skuId
          );
          if (categoryIndex >= 0 && productIndex >= 0) {
            this.products[categoryIndex].products[productIndex].quantity =
              this.cartNumper;
          }
          if (searchProductIndex >= 0) {
            this.searchProducts[searchProductIndex].quantity = this.cartNumper;
          }
          this.cartList = JSON.parse(JSON.stringify(this.cartList));
          this.products = JSON.parse(JSON.stringify(this.products));
        }
      }
    },
    isPositiveInteger(str) {
      return /^\d+$/.test(str);
    },
    deleteCartProduct() {
      let index = this.cartList.findIndex(
        (item) =>
          item.spuId == this.currentRow.spuId &&
          item.skuId == this.currentRow.skuId
      );
      if (index >= 0) {
        let categoryIndex, productIndex, searchProductIndex,allProductIndex;
        categoryIndex = this.products.findIndex(
          (item) => item.categoryId == this.currentRow.categoryId
        );
        productIndex = this.products[categoryIndex].products.findIndex(
          (item) =>
            item.spuId == this.currentRow.spuId &&
            item.skuId == this.currentRow.skuId
        );
        allProductIndex= this.products[0].products.findIndex(
          (item) =>
            item.spuId == this.currentRow.spuId &&
            item.skuId == this.currentRow.skuId
        );
        searchProductIndex = this.searchProducts.findIndex(
          (item) =>
            item.spuId == this.currentRow.spuId &&
            item.skuId == this.currentRow.skuId
        );
        if (categoryIndex >= 0 && productIndex >= 0) {
          this.products[categoryIndex].products[productIndex].quantity = 0;
        }
         if (allProductIndex >= 0) {
          this.products[0].products[allProductIndex].quantity = 0;
        }
        if (searchProductIndex >= 0) {
          this.searchProducts[searchProductIndex].quantity = 0;
        }
        this.cartList.splice(index, 1);
        if (index >= this.cartList.length) {
          this.currentRow = this.cartList[this.cartList.length - 1];
        } else {
          this.currentRow = this.cartList[index - 1];
        }
        if (this.currentRow) {
          this.cartNumper = this.currentRow.quantity;
        }
        if (this.cartList.length == 0) {
          this.cartNumper = 1;
          this.orderRemarkValue = "";
          this.orderInfo.remark = "";
        }
      }
    },
    clearCart() {
      this.cartList.forEach((product) => {
        let categoryIndex, productIndex, searchProductIndex;
        categoryIndex = this.products.findIndex(
          (item) => item.categoryId == product.categoryId
        );
        productIndex = this.products[categoryIndex].products.findIndex(
          (item) => item.spuId == product.spuId && item.skuId == product.skuId
        );
        searchProductIndex = this.searchProducts.findIndex(
          (item) => item.spuId == product.spuId && item.skuId == product.skuId
        );
        if (categoryIndex >= 0 && productIndex >= 0) {
          this.products[categoryIndex].products[productIndex].quantity = 0;
        }
        if (searchProductIndex >= 0) {
          this.searchProducts[searchProductIndex].quantity = 0;
        }
      });
      this.cartNumper = 1;
      // 遍历 cartList 数组，将 quantity 属性置为 0
      this.cartList.forEach((product) => {
        product.quantity = 0;
      });
      this.cartList = [];
      this.products.forEach((category) => {
        category.products.forEach((product) => {
          product.quantity = 0;
        });
      });

      this.searchProducts.forEach((product) => {
        product.quantity = 0;
      });

      this.orderRemarkValue = "";
      this.orderInfo = {};
      this.params = {
        orderId: "",
        customId: 0,
        pointDeduction: false,
        memberDiscounts: false,
      };
      this.userInfo = {};
      this.memberPhoneNumber = "";
      this.$addKeydownListener();
    },
    tagClick(tagName) {
      if (this.productRemarkValue && this.productRemarkValue.trim() != "") {
        if (this.productRemarkValue.search(tagName) == -1) {
          this.productRemarkValue = this.productRemarkValue + "," + tagName;
        }
      } else {
        this.productRemarkValue = tagName;
      }
      let index = this.cartList.findIndex(
        (item) =>
          item.spuId == this.currentRow.spuId &&
          item.skuId == this.currentRow.skuId
      );
      this.cartList[index].remark = this.productRemarkValue;
      this.cartList = JSON.parse(JSON.stringify(this.cartList));
      setTimeout(() => {
        this.$refs["productRemark"].focus();
      }, 0);
    },
    showProductRemarkBut() {
      if (this.currentRow.productTags) {
        this.remarkProductTags = JSON.parse(this.currentRow.productTags);
      } else {
        this.remarkProductTags = [];
      }
      this.productRemarkValue = this.currentRow.remark;
      setTimeout(() => {
        this.$refs["productRemark"].focus();
      }, 0);
    },
    remarkBut(close) {
      let index = this.cartList.findIndex(
        (item) =>
          item.spuId == this.currentRow.spuId &&
          item.skuId == this.currentRow.skuId
      );
      this.cartList[index].remark = this.productRemarkValue;
      this.cartList = JSON.parse(JSON.stringify(this.cartList));
      if (close) {
        this.productRemarkValue = " ";
        this.$refs["remarkPop"].doClose();
      }
    },

    productRemarkClear() {
      let index = this.cartList.findIndex(
        (item) =>
          item.spuId == this.currentRow.spuId &&
          item.skuId == this.currentRow.skuId
      );
      this.cartList[index].remark = "";
      this.cartList = JSON.parse(JSON.stringify(this.cartList));
      this.productRemarkValue = "";
    },

    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },
    async onRowClick(row) {
      // console.log(row);
      let cart = this.cartList[row.index];
      this.cartNumper = cart.quantity;
      this.currentRow = cart;
      const res = await getInventory({
        spuId: row.spuId,
        skuId: row.skuId,
      });
      this.inventory = res.data;
    },
    visibleBut() {
      this.orderRemarkVisible = !this.orderRemarkVisible;
      if (this.orderRemarkVisible) {
        setTimeout(() => {
          this.$refs["orderRemarkValue"].focus();
        }, 0);
      }
    },
    submitRemark() {
      this.orderRemarkVisible = false;
      this.orderInfo.remark = this.orderRemarkValue;
    },
    orderRemarkClear() {
      this.orderRemarkVisible = false;
      this.orderInfo.remark = "";
    },
    submitOrder() {
      this.submitDisabled = true;
      window.addEventListener("keydown", this.handleBarcodeScan);
      this.$removeKeydownListener();
      this.orderInfo.storeId = this.storeInfo.id;
      this.orderInfo.orderNumber = this.orderNumber;
      let skus = [];
      this.cartList.forEach((product) => {
        skus.push({
          spuId: product.spuId,
          skuId: product.skuId,
          categoryId: product.categoryId,
          spuCode: product.spuCode,
          skuCode: product.skuCode,
          categoryCode: product.categoryCode,
          spuName: product.spuName,
          skuName: product.skuName,
          price: product.price,
          quantity: product.quantity,
          remark: product.remark,
          productPic: product.productPic,
        });
      });
      this.orderInfo.skus = skus;
      addOrder(this.orderInfo).then((response) => {
        if (response.code === 200) {
          // console.log(response);
          this.foodOrder = response.data;
          this.orderInfo.id = this.foodOrder.id;
          this.params.orderId = this.foodOrder.id;
          this.getDiscounts();
          this.barcodeValue = null;
          this.dialogFormVisible = true;
        } else {
          this.$message({ message: response.msg, type: "warning" });
        }
        this.submitDisabled = this.cartList.length == 0;
      });
    },
    async getDiscounts() {
      const res = await getDiscounts(this.params);
      // console.log(res);
      if (res.code == 200) {
        this.discounts = res.data;
        this.orderDetail = res.data.foodOrderSkuList;
        // console.log(this.discounts);
      }
    },
    cancelOrder() {
      let query = {
        oderId: this.orderInfo.id,
      };
      cancelOrder(query).then((response) => {
        if (response.code == 200) {
          //取消成功
          this.clearCart();
          this.getOrderNumber();
          this.dialogFormVisible = false;
          this.operateStatus = 1;
          this.stateOfPayment = 1;
          this.btnAction = "CASH_PAYMENT";
          this.paymentMethod = "CASH_PAYMENT";
          (this.officialReceipts = 0), //实收
            (this.getBack = 0); //退回
        } else {
          this.$notify.error({
            title: "提示",
            message: response.msg,
            duration: 10000,
          });
        }
      });
    },
    editOrder() {
      let query = {
        oderId: this.orderInfo.id,
      };
      checkOrderEdit(query).then((response) => {
        if (response.code == 200) {
          this.dialogFormVisible = false;
          this.stateOfPayment = 1;
          this.$addKeydownListener();
        } else {
          this.$notify.error({
            title: "提示",
            message: response.msg,
            duration: 10000,
          });
        }
      });
    },
    handleBarcodeScan(e) {
      if (this.lastTime == null) {
        this.lastTime = new Date().getTime();
        this.code += this.dispose(e.key);
      } else {
        let nowTime = new Date().getTime();
        if (e.keyCode === 13 || e.key == "Enter") {
          if (nowTime - this.lastTime < 80 && this.code.length > 15) {
            //在这里请求支付接口
            this.code = this.code.toUpperCase();
            this.code = this.code.replace(/[^0-9ABCDE]/g, "");
            this.barcodeHandle();
            // console.log(this.code);
          }
          this.code = "";
        } else {
          if (nowTime - this.lastTime < 9) {
            this.code += this.dispose(e.key);
          } else {
            this.code = this.dispose(e.key);
          }
        }
        this.lastTime = nowTime;
      }
    },
    //扫码枪输入校验
    dispose(value) {
      value = value.toUpperCase();
      let reg = /^[A-E0-9]+$/;
      if (reg.test(value)) {
        return value;
      } else {
        return "";
      }
    },
    barcodeHandle() {
      if (this.operateStatus === 1) {
        if (this.paymentMethod === "MICRO_PAYMENT") {
          this.code = this.code.replace(/[^0-9ABCDE]/g, "");
          this.confirmPayment();
        } else {
          this.code = "";
        }
      } else if (this.operateStatus === 4) {
        this.code = this.code.replace(/[^0-9ABCDE]/g, "");
        this.submitWriteOff();
      } else {
        this.code = "";
      }
    },
    //  确认支付
    confirmPayment() {
      this.paymentLoading = true;
      let paymentAuthCode;
      if (
        this.paymentMethod === "MICRO_PAYMENT" &&
        this.code != null &&
        this.code.length > 0
      ) {
        paymentAuthCode = this.code;
        this.code = null;
      } else {
        paymentAuthCode = null;
      }
      let params = {
        orderId: this.orderInfo.id,
        paymentMethod: this.paymentMethod,
        paymentAuthCode: paymentAuthCode,
        customId: this.params.customId,
        pointDeduction: this.params.pointDeduction,
        memberDiscounts: this.params.memberDiscounts,
      };
      this.isProcessing = true;
      this.isScan = true;
      storePayment(params)
        .then((response) => {
          if (response.code == 200) {
            let paymentData = response.data;
            if (paymentData.paymentStatus == "PAYMENT_SUCCESS") {
              printOrder(params.orderId);
              this.getSpeechList("010101000001");
              this.$addKeydownListener();
            }
            if (paymentData.paymentMethod == "MICRO_PAYMENT") {
              //付款码支付
              if (paymentData.paymentStatus == "PAYMENT_SUCCESS") {
                //支付成功
                this.getOrderNumber();
                this.clearCart();
                this.getSpeechList("010101000001");
                this.dialogFormVisible = false;
                this.operateStatus = 1;
                this.stateOfPayment = 1;
              } else if (paymentData.paymentStatus == "PAYMENT_PROGRESS") {
                this.stateOfPayment = 2;
                this.getSpeechList("010301000008");
                if (this.stateOfPayment == 2) {
                  this.timeoutId = setTimeout(() => {
                    // paymentData.paymentStatus == "PAYMENT_FAIL";
                    this.getPaymentStatus(params.orderId);
                  }, 60000); // 60秒后执行
                }
              } else {
                //支付失败
                this.stateOfPayment = 3;
                this.getSpeechList("010201000002");
              }
            } else if (paymentData.paymentMethod == "CASH_PAYMENT") {
              if (paymentData.paymentStatus == "PAYMENT_SUCCESS") {
                //现金支付成功
                this.getOrderNumber();
                this.clearCart();
                this.getSpeechList("010101000001");
                this.dialogFormVisible = false;
                this.operateStatus = 1;
                this.stateOfPayment = 1;
                this.btnAction = "CASH_PAYMENT";
                this.paymentMethod = "CASH_PAYMENT";
                this.getStoreInfo();
                this.$notify.success({
                  title: "提示",
                  message: "支付成功",
                  duration: 8000,
                });
              } else {
                this.getSpeechList("010201000002");
                this.$notify.error({
                  title: "错误",
                  message: "现金支付失败请稍后重试",
                  duration: 8000,
                });
              }
            } else if (paymentData.paymentMethod == "MEMBER_BALANCE_PAYMENT") {
              if (paymentData.paymentStatus == "PAYMENT_SUCCESS") {
                //会员余额支付成功
                this.getOrderNumber();
                this.clearCart();
                this.getSpeechList("010101000001");
                this.paymentMethod = "CASH_PAYMENT";
                this.btnAction = "CASH_PAYMENT";
                this.dialogFormVisible = false;
                this.operateStatus = 1;
                this.stateOfPayment = 1;
                this.getStoreInfo();
                this.$notify.success({
                  title: "提示",
                  message: "会员余额支付成功",
                  duration: 8000,
                });
              } else {
                this.getSpeechList("010201000002");
                this.isProcessing = false;
                this.isScan = false;
                this.$notify.error({
                  title: "错误",
                  message: paymentData.message,
                  duration: 8000,
                });
              }
            } else if (paymentData.paymentMethod == "SCAN_CODE_PAYMENT") {
               if (paymentData.paymentStatus == "PAYMENT_SUCCESS") {
                  this.getOrderNumber();
                  this.clearCart();
                  this.getSpeechList("010101000001");
                  this.btnAction = "CASH_PAYMENT";
                  this.paymentMethod = "CASH_PAYMENT";
                  this.dialogFormVisible = false;
                  this.operateStatus = 1;
                  this.stateOfPayment = 1;
                  this.getStoreInfo();
                  this.$notify.success({
                    title: "提示",
                    message: "扫码支付成功",
                    duration: 8000,
                  });
              }else{
                this.getSpeechList("010201000002");
                this.isProcessing = false;
                this.isScan = false;
                  this.$notify.error({
                   title: "提示",
                   message: "扫码支付失败",
                   duration: 8000,
                 });
              }
            } else {
              this.getSpeechList("010201000002");
              this.isProcessing = false;
              this.isScan = false;
            }
          }else{
            this.isProcessing = false;
            this.isScan = false;
            this.stateOfPayment = 3;
            this.getSpeechList("010201000002");
          }
          this.paymentLoading = false;
        }).catch((error) => {
          this.paymentLoading = false;
          this.isProcessing = false;
          this.isScan = false;
          this.getSpeechList("010201000002");
          this.$addKeydownListener();
        });
    },
    paymentNotice(paymentStatus) {
      if (paymentStatus == 0) {
        //待支付
        this.operateStatus = 1;
        this.stateOfPayment = 1;
      } else if (paymentStatus == 1) {
        // 支付中
        this.operateStatus = 2;
        this.stateOfPayment = 2;
        this.getSpeechList("010301000008");
      } else if (paymentStatus == 2) {
        //支付成功
        this.getOrderNumber();
        this.clearCart();
        this.btnAction = "CASH_PAYMENT";
        this.paymentMethod = "CASH_PAYMENT";
        this.dialogFormVisible = false;
        this.operateStatus = 1;
        this.stateOfPayment = 1;
        this.getSpeechList("010201000001");
        if (this.timeoutId) {
          clearTimeout(this.timeoutId);
        }
        this.$notify.success({
          title: "提示",
          message: "支付成功",
          duration: 8000,
        });
        this.$addKeydownListener();
      } else if (paymentStatus == -1) {
        //支付失败
        if (this.timeoutId) {
          clearTimeout(this.timeoutId);
        }
        this.getSpeechList("010201000002");
        
        this.stateOfPayment = 3;
        this.$notify.error({
          title: "错误",
          message: "支付失败,请重新支付",
          duration: 0,
        });
      }
    },
    async getPaymentStatus(orderId) {
      const res = await getPaymentStatus(orderId);
      let paymentData = res.data;
      if (paymentData.paymentStatus == "PAYMENT_SUCCESS") {
        this.dialogFormVisible = false;
        this.operateStatus = 1;
        this.stateOfPayment = 1;
        printOrder(orderId);
        this.getOrderNumber();
        this.clearCart();
        this.$notify.success({
          title: "提示",
          message: "支付成功",
          duration: 8000,
        });
      } else if (paymentData.paymentStatus == "PAYMENT_FAIL") {
        this.stateOfPayment = 3;
        this.isProcessing = false;
        this.isScan = false;
        this.$notify.error({
          title: "错误",
          message: "支付失败",
          duration: 0,
        });
      }
      this.$addKeydownListener();
    },
    keyDow() {
      if (this.cartNumper > this.inventory) {
        this.cartNumper = this.inventory;
      }
      this.editReduceQuantity();
    },
    openWriteOffView() {
      this.$refs.manualVerifiCation.openModal();
    },
    //更换用户
    changePhone() {
      if (this.memberPhoneNumber.length <= 11) {
        this.memberPhoneNumber = "";
      }
      this.memberPayment = false;
      this.btnAction = "CASH_PAYMENT";
      this.userInfo = {};
      this.params.customId = 0;
      this.params.pointDeduction = false;
      this.params.memberDiscounts = false;
      this.getDiscounts();
    },
    deleteOrder() {
      this.$confirm("是否确认返回上一页面")
        .then(() => {
          this.dialogFormVisible = false;
          this.orderInfo.id = null;
          this.$addKeydownListener();
          this.getStoreInfo();
        })
        .catch(() => {});
    },
  },
  watch: {
    cartList: function (carts) {
      this.$nextTick(() => {
        this.submitDisabled = carts.length == 0;
        if (this.currentRow) {
          this.currentRow = this.cartList.find(
            (item) =>
              item.spuId == this.currentRow.spuId &&
              item.skuId == this.currentRow.skuId
          );
        } else {
          this.currentRow = this.cartList[0];
        }
        this.$refs.cartTable.setCurrentRow(this.currentRow);
        //计算总价
        let amount = 0;
        carts.forEach((product) => {
          amount += product.subtotalAmount;
        });
        this.totalAmount = amount;
        this.writerData(this.totalAmount);
      });
    },
    paymentMethod: function (paymentMethod) {
      if (paymentMethod === "MICRO_PAYMENT") {
        window.addEventListener("keydown", this.handleBarcodeScan);
      } else {
        window.removeEventListener("keydown", this.handleBarcodeScan);
      }
    },
    verification: function (verification) {
      if (verification == 2) {
        this.$nextTick(() => {
          this.$refs["inputButton"].focus();
        });
      } else {
        window.addEventListener("keydown", this.handleBarcodeScan);
      }
    },
  },
};
</script>
<style src="@/style/ShoppingCart.css"  scoped></style>
