<template>
  <el-form ref="form" label-width="200px">
    <el-form-item label="开启客显">
      <el-switch v-model="customerDisplay" active-color="#E6A23C" @change="customerDisplayChange"></el-switch>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: "Setings",
  data(){
    return {
      customerDisplay: false,
      portAuthStatus: false,
    }
  },
  created() {

  },
  mounted() {
    this.noBackward();
    this.customerDisplayStatus();
    // this.openSerial();
  },
  methods:{
    noBackward() {
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate",function(e) {
        history.pushState(null, null, document.URL);
      }, false);
    },
    async customerDisplayStatus(){
      if("serial" in navigator) {
        await navigator.serial.getPorts().then((ports) => {
          if((ports !== null) && (ports !== '') && (Array.isArray(ports)) && (ports.length > 0)){
            this.customerDisplay = true;
          }else{
            this.customerDisplay = false;
          }
        });
      }else{
        this.customerDisplay = false;
      }
    },
    customerDisplayChange(){
      if(this.customerDisplay){
        if("serial" in navigator) {
          this.openCustomerDisplay();
        }else{
          this.$message({
            message: '当前浏览器不支持串口通信',
            type: 'error'
          });
        }
      }
    },
    async openCustomerDisplay(){
      try {
        await navigator.serial.requestPort().then((port) => {
          this.customerDisplay = true
        }, error => {
          this.customerDisplay = false
          this.$message({
            message: '请选择正确的客显串口',
            type: 'error'
          });
        });

      } catch (e){
        this.customerDisplay = false
        this.$message({
          message: '请选择正确的客显串口',
          type: 'error'
        });
      }
    }
  }
}
</script>

<style scoped>

</style>