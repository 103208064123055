<template>
  <div class="content">
    <el-dialog
      center
      title="小票打印"
      width="80%"
      ref="refsTable"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      class="payment-dialog"
    >
      <el-table
        :data="data"
        v-loading="loading"
        v-if="data.length > 0"
        height="450px"
      >
        <el-table-column
          prop="orderNumber"
          align="center"
          label="订单号"
        ></el-table-column>
        <el-table-column
          property="paymentAmount"
          width="100"
          align="center"
          label="订单金额"
        >
          <template v-slot="{ row }">
            <span>￥{{ row.paymentAmount | toFixed2Filter }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          align="center"
          label="下单时间"
        ></el-table-column>
        <el-table-column
          prop="支付方式"
          width="100"
          align="center"
          label="支付方式"
        >
          <template v-slot="{ row }">
            <el-tag type="danger" v-if="row.paymentMethod == -1">未知</el-tag>
            <el-tag type="info" v-if="row.paymentMethod == 0">现金</el-tag>
            <el-tag type="success" v-if="row.paymentMethod == 1">小程序</el-tag>
            <el-tag v-if="row.paymentMethod == 2">付款码</el-tag>
            <el-tag type="warning" v-if="row.paymentMethod == 3">扫码</el-tag>
            <el-tag type="warning" v-if="row.paymentMethod == 4"
              >会员余额</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          property="paymentTime"
          align="center"
          label="支付时间"
        ></el-table-column>
        <el-table-column
          property="printStatus"
          align="center"
          width="100px"
          label="打印状态"
        >
          <template v-slot="{ row }">
            <el-tag type="danger" v-if="row.printStatus == -1">打印失败</el-tag>
            <el-tag type="info" v-if="row.printStatus == 0">未打印</el-tag>
            <el-tag type="success" v-if="row.printStatus == 1">打印成功</el-tag>
          </template>
        </el-table-column>
        <el-table-column width="100px" align="center" label="操作">
          <template v-slot="{ row }">
            <el-button
              type="text"
              style="font-size: 20px"
              @click="printOrder(row.id)"
              >打印</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-empty v-else description="暂无未打印小票的数据"></el-empty>
      <div slot="footer" class="dialog-footer">
        <el-button type="warning" @click="handleClose">返 回</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import cache from "@/utils/cache";
import { getPrints } from "@/api/index";
import { BASE_URL } from "@/config/config";
import { executePrinting } from "@/api/printapi";
export default {
  name: "repairPrint",
  data() {
    return {
      dialogVisible: false,
      storeId: 0,
      data: [],
      ids: [],
      loading: true,
    };
  },
  methods: {
    async getPrints() {
      this.storeId = cache.session.getJSON("storeInfo");
      // console.log(this.storeId);
      this.loading = true;
      const res = await getPrints(this.storeId.id);
      // console.log(res);
      if (res.code == 200) {
        this.data = res.data;
      }
      this.loading = false;
    },
    openModal() {
      this.dialogVisible = true;
      this.getPrints();
    },
    printOrder(id) {
      //构建打印参数
      this.loading = true;
      let printParams = {
        printType: 1,
        printerName: "POS58",
        dataUrl: BASE_URL + "/print/store/receipt/" + id,
        template: null,
        notifyUrl: BASE_URL + "/print/notify/" + 1 + "/" + id,
      };
      executePrinting(printParams).then((response) => {
          if (response === "SUCCESS") {
            this.$notify.success({
              title: "提示",
              message: "打印成功",
              duration: 5000,
            });
          } else {
            this.$notify.error({
              title: "错误",
              message: "打印失败",
              duration: 8000,
            });
          }
          setTimeout(() => {
            this.getPrints();
          }, 2000);
        })
        .catch((error) => {
          this.$notify.error({
            title: "错误",
            message: "打印失败,请检查打印程序状态",
            duration: 8000,
          });
          setTimeout(() => {
            this.getPrints();
          }, 2000);
        });
    },
    handleClose() {
      this.getPrints();
      this.dialogVisible = false;
    },
  },
};
</script>

<style scoped>
::v-deep .payment-dialog .el-dialog__header {
  padding: 20px 0px !important;
  width: 100% !important;
  height: 26px !important;
  line-height: 26px;
  border-bottom: 1px solid #ccc;
}
::v-deep .el-dialog__title {
  width: 100px !important;
  margin-left: 20px;
}
::v-deep .el-table__header-wrapper .el-table__header .el-checkbox {
  display: none;
}
</style>