import server from "../utils/http.js";
// 获取商品列表
export const getOrderList = (params) =>server.get("/order/food/searchFoodOrderList",{params});
// 退款
export const getPay = id =>server.get(`/order/food/store/refund/${id}`);

// 手动核销
export const getCancelList = (params) =>server.get("/order/food/selectWriteoffOrder",{params});

// 确认核销
export const orderWriteOffs = (params) =>server.post("/order/food/selectWriteoffOrder",params);



