<template>
  <div class="login">
    <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form">
      <h3 class="title">诸葛小二收银系统</h3>
      <el-form-item prop="username">
        <el-input
            v-model="loginForm.username"
            type="text"
            auto-complete="off"
            placeholder="账号">
          <el-icon slot="prefix" class="el-icon-user-solid input-icon" />
        </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
            v-model="loginForm.password"
            type="password"
            auto-complete="off"
            placeholder="密码"
            @keyup.enter.native="handleLogin"
        >
          <el-icon slot="prefix" class="el-icon-lock input-icon" />
        </el-input>
      </el-form-item>
      <el-form-item prop="code" v-if="captchaEnabled">
        <el-input
            v-model="loginForm.code"
            auto-complete="off"
            type="number"
            class="no-number"
            placeholder="验证码"
            style="width: 63%"
            @keyup.enter.native="handleLogin"
        >
          <el-icon slot="prefix" class="el-icon-key input-icon" />
        </el-input>
        <div class="login-code">
          <img :src="codeUrl" @click="getCode" class="login-code-img"/>
        </div>
      </el-form-item>
      <el-checkbox v-model="loginForm.rememberMe" style="margin:0px 0px 25px 0px;">记住密码</el-checkbox>
      <el-form-item style="width:100%;">
        <el-button
            :loading="loading"
            type="primary"
            style="width:100%;"
            @click.native.prevent="handleLogin"
            round="round">
          <span v-if="!loading">登 录</span>
          <span v-else>登 录 中...</span>
        </el-button>
      </el-form-item>
    </el-form>
    <!--  底部  -->
    <div class="el-login-footer">
      <span>Copyright © 2023-2028 zhugexiaoer.com All Rights Reserved.</span>
    </div>
  </div>
</template>
<script>
import {getCodeImg, getUserInfo, login} from "@/api/index"
import { setToken, addCookies, removeCookies, getCookie } from '@/utils/auth'
import cache from '@/utils/cache'
import { jwtDecode } from 'jwt-decode'
import screenfull from "screenfull"
export default {
  name: "Login",
  data() {
    return {
      codeUrl: "",
      isFullscreen: false,
      loginForm: {
        username: "",
        password: "",
        rememberMe: false,
        code: "",
        uuid: ""
      },
      loginRules: {
        username: [
          { required: true, trigger: "blur", message: "请输入您的账号" }
        ],
        password: [
          { required: true, trigger: "blur", message: "请输入您的密码" }
        ],
        code: [{ required: true, trigger: "change", message: "请输入验证码" }]
      },
      loading: false,
      captchaEnabled: true, //验证码开关
      redirect: undefined
    };
  },
  created() {
    this.getCode();
    this.getCookie();
  },
  mounted() {
    this.noBackward();
  },
  beforeDestroy() {
    // 取消监听事件
  },
  methods: {
    noBackward() {
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate",function(e) {
        history.pushState(null, null, document.URL);
      }, false);
    },
    getCode() {
      getCodeImg().then(res => {
        this.captchaEnabled = res.captchaEnabled === undefined ? true : res.captchaEnabled;
        if (this.captchaEnabled) {
          this.codeUrl = "data:image/gif;base64," + res.img;
          this.loginForm.uuid = res.uuid;
        }
      });
    },
    getCookie() {
      let cookieData = getCookie();
      this.loginForm.username = cookieData.username ? cookieData.username : this.loginForm.username;
      this.loginForm.password = cookieData.password ? cookieData.password : this.loginForm.password;
      this.loginForm.rememberMe = cookieData.rememberMe ? cookieData.rememberMe : false;
    },
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true;
          if (this.loginForm.rememberMe) {
            addCookies(this.loginForm);
          } else {
            removeCookies();
          }
          login({
            clientType: 2,
            username: this.loginForm.username,
            password: this.loginForm.password,
            code: this.loginForm.code,
            uuid: this.loginForm.uuid
          }).then(response => {
            if(response.code === 200){
              setToken(response.token);
              let jwtToken = jwtDecode(response.token);
              cache.local.set("userid", jwtToken.userid);
              cache.session.set("userid", jwtToken.userid);
              this.getUserInfo();
            }else{
              this.$message({
                message: response.msg,
                type: 'warning'
              });
              this.getCode();
              this.loading = false;
            }
          });
        }
      });
    },
    screen() {
      let winFlag = window.innerHeight === window.screen.height;
      let isFull = window.fullScreen || document.webkitIsFullScreen
      if (isFull === undefined) {
        this.isFullscreen = winFlag
      } else {
        this.isFullscreen = winFlag || isFull
      }
      if(!this.isFullscreen){
        screenfull.toggle();
      }
    },
    getUserInfo() {
      getUserInfo().then(response => {
        if(response.code === 200){
          // console.log(response);
          cache.session.setJSON("userInfo", response.user);
          cache.session.setJSON("storeInfo", response.storeInfo);
          this.screen();
          //  window.location.reload();
          this.$router.push({ path: '/' });
          // this.$router.push('/');
        }else{
          this.$message({
            message: response.msg,
            type: 'warning'
          });
          this.getCode();
        }
        this.loading = false;
      });
    },
  }
}
</script>

<style>
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-image: url("../assets/images/login-background.jpg");
  background-size: cover;
}
.title {
  margin: 0px auto 30px auto;
  text-align: center;
  color: #707070;
}

.login-form {
  border-radius: 6px;
  background: #ffffff;
  width: 300px;
  padding: 25px 25px 5px 25px;

}

.login-form .el-input {
  height: 38px;
}

.login-form .el-input input {
  height: 38px;
}

.input-icon {
  height: 39px;
  width: 14px;
  margin-left: 5px;
}

.login-tip {
  font-size: 13px;
  text-align: center;
  color: #bfbfbf;
}
.login-code {
  width: 33%;
  height: 38px;
  float: right;
}
.login-code img {
  cursor: pointer;
  vertical-align: middle;
}

.el-login-footer {
  height: 40px;
  line-height: 40px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  font-family: Arial;
  font-size: 12px;
  letter-spacing: 1px;
}
.login-code-img {
  height: 38px;
}

::v-deep .no-number::-webkit-outer-spin-button,
.no-number::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none !important;
}

.no-number input[type="number"]::-webkit-outer-spin-button,
.no-number input[type="number"]::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none !important;
}

</style>