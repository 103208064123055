import { render, staticRenderFns } from "./ShoppingCart.vue?vue&type=template&id=3522fe50&scoped=true"
import script from "./ShoppingCart.vue?vue&type=script&lang=js"
export * from "./ShoppingCart.vue?vue&type=script&lang=js"
import style0 from "@/style/ShoppingCart.css?vue&type=style&index=0&id=3522fe50&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3522fe50",
  null
  
)

export default component.exports