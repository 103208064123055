import Cookies from 'js-cookie'
import {encrypt,decrypt} from "@/utils/jsencrypt";

const TokenKey = 'Admin-Token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function addCookies(data) {
  Cookies.set("username", data.username, { expires: 30 });
  Cookies.set("password", encrypt(data.password), { expires: 30 });
  Cookies.set('rememberMe', data.rememberMe, { expires: 30 });
}

export function getCookie() {
  let data = {
    username: Cookies.get("username"),
    password: decrypt(Cookies.get("password")),
    rememberMe: Boolean(Cookies.get("rememberMe"))
  }
  return data;
}

export function removeCookies() {
  Cookies.remove("username");
  Cookies.remove("password");
  Cookies.remove('rememberMe');
};

