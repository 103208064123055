import { orderWriteOff} from "@/api/index";
import { printOrder } from "@/router/paymentsucce.js";
export const smqMixins = {
  data() {
    return {
      code: ""
    }
  },
  mounted() {
    this.$EventBus.$on("smq", (data) => {
      const verifiCationComponent = this.$refs.verifiCation;
      if (verifiCationComponent) {
        // 直接调用 openModal 方法
        this.openModal(verifiCationComponent);
        this.code = data;
      } else {
        // console.error("verifiCationComponent is undefined");
        const verifiCationComponent = this.$refs.verifiCation;
        this.openModal(verifiCationComponent);
        this.code = data;
      }
    });
  },
  methods: {
    openModal(verifiCationComponent, retryCount = 3) {
      if (retryCount <= 0) {
        // console.error("已达到的最大重试计数。不能调用openModal");
        return;
      }
      if (verifiCationComponent && typeof verifiCationComponent.openModal === 'function') {
        this.$nextTick(() => {
          // 确保在下一个 tick 中调用 openModal 方法
          verifiCationComponent.openModal();
          // 调用 submitWriteOff 方法
          this.submitWriteOff(verifiCationComponent);
        });
      } else {
         this.openModal(verifiCationComponent, retryCount - 1);
      }
    },
    submitWriteOff(verifiCationComponent) {
      if (verifiCationComponent.operateStatus === 4) {
        const writeOffCode = this.code;
        if (writeOffCode && writeOffCode != "") {
          orderWriteOff({ writeOffCode }).then((response) => {
            if (response.code === 200) {
              printOrder(response.data);
              verifiCationComponent.writeOffStatus = 1;
              verifiCationComponent.getSpeechList('020101000003')
              setTimeout(() => {
                verifiCationComponent.dialogFormVisible = false;
              }, 3000);
            } else {
              verifiCationComponent.writeOffStatus = -1;
              verifiCationComponent.getSpeechList('020201000004')
              verifiCationComponent.writeOffErrorMsg = response.msg;
            }
            verifiCationComponent.code = "";
          }).catch((error) => {
            if (error.code === "ECONNABORTED") {
              this.$message.error("网络请求超时");
              verifiCationComponent.code = "";
            }
          });
        }
      }
    }
  }
}