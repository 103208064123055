<template>
<div>
    <!-- 手动核销 -->
  <el-dialog
    width="45%"
    append-to-body
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
    :show-close="false"
    class="verification"
  >
    <div v-if="operateStatus == 4" style="text-align: center;">
      <!-- 手动核销 -->
      <div v-show="verification == 2" class="payment-result">
        <div style="margin-bottom: 30px; margin-top: 100px">
          <el-input
            class="inputButton"
            clearable
            ref="inputButton"
            placeholder="请输入订单号"
            v-model="searchValue"
            @clear="getUserList"
          >
            <el-button slot="append" @click="getOrderList">查询</el-button>
          </el-input>
        </div>
        <template>
          <el-table
            v-if="this.Successful == 0 && this.orderList.length > 0"
            ref="multipleTable"
            :data="orderList"
            tooltip-effect="dark"
            style="width: 100%; margin-bottom: 100px"
            highlight-current-row
            @current-change="handleSelectionChange"
          >
            <el-table-column type="index" width="55"> </el-table-column>
            <el-table-column
              prop="orderNumber"
              width="220"
              label="订单号"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="paymentAmount"
              label="订单金额"
              show-overflow-tooltip
            >
              <template v-slot="{ row }">
                ￥{{ row.totalAmount | toFixed2Filter }}
              </template>
            </el-table-column>
            <el-table-column
              prop="paymentAmount"
              label="支付金额"
              show-overflow-tooltip
            >
              <template v-slot="{ row }">
                ￥{{ row.paymentAmount | toFixed2Filter }}
              </template>
            </el-table-column>
            <el-table-column prop="createTime" label="日期" width="180">
            </el-table-column>
          </el-table>
          <el-result
            v-if="this.Successful == 1"
            icon="success"
            title="订单核销成功"
            subTitle="订单核销成功，请凭借订单小票取用餐品！"
          ></el-result>
          <el-result
            v-show="this.Successful == -2"
            icon="error"
            title="订单查询失败"
            subTitle="失败原因：根据查询条件未查询到相关待核销订单"
          ></el-result>
          <el-result
            v-show="this.Successful == -3"
            icon="error"
            title="订单查询失败"
            subTitle="失败原因：请输入正确的订单号"
          ></el-result>
          <el-result
            v-show="this.Successful == -1"
            icon="error"
            title="订单核销失败"
            :subTitle="this.writeOffErrorMsg"
          ></el-result>
        </template>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="info"
          @click="closeWriteOffView"
          v-show="operateStatus == 4"
          round
          >返回点餐</el-button
        >
        <el-button
          type="warning"
          @click="confirmCancel"
          v-show="verification == 2 && showBtn"
          round
          >确认核销</el-button
        >
      </div>
    </div>
  </el-dialog>
    <div class="audio">
       <audio ref="audioPlayer" :src="mp3" controls></audio>
    </div>
</div>
</template>

<script>
import { getCancelList } from "@/api/order";
import { printOrder } from "@/router/paymentsucce.js";
import { orderWriteOff} from "@/api/index";
import { BASE_URL } from "@/config/config";
import {playSpeech} from "@/utils/speech";
import cache from "@/utils/cache";
export default {
  name: "manualVerifiCation",
  data() {
    return {
      storeInfo: {},
      dialogFormVisible: false,
      barcodeValue: null,
      operateStatus: 1,
      writeOffStatus: 0,
      verification: 2,
      writeOffErrorMsg: "系统异常，请联系系统管理员！",
      searchValue: "",
      Successful: 0, // 核销状态
      orderList: [],
      showBtn: true,
      cancelOrderNumber: "",
      mp3:'',
      apiUrl: BASE_URL,
    };
  },
  created() {
    let storeInfo = cache.session.getJSON("storeInfo");
    if (storeInfo) {
      this.storeInfo = storeInfo;
    }
  },
  methods: {
    openModal() {
      this.barcodeValue = null;
      this.dialogFormVisible = true;
      this.operateStatus = 4;
      this.writeOffStatus = 0;
    },
    getUserList() {
      this.Successful = 0;
    },
      // 选中核销的订单号
    handleSelectionChange(e) {
      // console.log(e);
      this.cancelOrderNumber = e.orderNumber;
    },
    // 手动核销的操作
    async getOrderList() {
      if (
        this.searchValue &&
        this.searchValue != "" &&
        this.searchValue.length >= 6
      ) {
        this.showBtn = true;
        this.Successful = 0;
        const res = await getCancelList({
          storeId: this.storeInfo.id,
          searchValue: this.searchValue,
        });
        // console.log(res);
        if (res.data.length > 0) {
          this.orderList = res.data;
        } else {
          // this.$message.error('暂无数据')
          this.Successful = -2;
        }
      } else {
        this.Successful = -3;
      }
    },
    // 确认核销
    async confirmCancel() {
      if (this.operateStatus === 4) {
        const writeOffCode = this.cancelOrderNumber;
        if (writeOffCode && writeOffCode != "") {
          const res = await orderWriteOff({ writeOffCode });
          if (res.code = 200) {
            this.Successful = 1;
            this.showBtn = false;
            this.orderList = [];
            printOrder(res.data)
            this.$notify.success({
              title:'核销成功',
              message: '订单已成功核销',
              duration: 3000,
            })
            this.getSpeechList('020101000003')
          } else if (res.code == "ECONNABORTED") {
            this.$notify.error({
              title: "提示",
              message: "网络请求超时",
              duration: 10000,
            });
          } else {
            this.Successful = -1;
            this.getSpeechList('020201000004')
          }
        }
      }
    },
    pepayment() {
      this.barcodeValue = null;
      this.operateStatus = 1;
    },
    closeWriteOffView() {
      this.verification = 2;
      this.Successful = 0;
      this.orderList = [];
      this.dialogFormVisible = false;
      this.operateStatus = 1;
      this.writeOffStatus = 0;
      this.writeOffErrorMsg = "系统异常，请联系系统管理员！";
      this.barcodeValue = null;
      this.searchValue= ""
    },
    async getSpeechList(speechCode) {
      await playSpeech(speechCode, this.apiUrl, this.$data); 
    }
  },
};
</script>

<style scoped>
::v-deep .payment-result .el-result .el-result__title p {
  color: #f56c6c;
}
.payment-result {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.inputButton {
  width: 420px;
  height: 35px;
}
.dialog-footer {
  text-align: center;
  margin-top: 30px;
  padding-bottom: 60px;
}
.verification{
  margin-top: -3%;
}
.audio{
  display: none;
}
::v-deep .el-table__body tr.current-row > td.el-table__cell,
.el-table__body tr.selection-row > td.el-table__cell {
  background-color: #e6a23c;
  color: white;
}
::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  color: #fff;
  background-color: #e6a23c;
  border-color: #e6a23c;
  box-shadow: -1px 0 0 0 #e6a23c;
}
</style>
