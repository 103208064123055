import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
// 引入图片懒加载
import lazyLoad from './utils/lazyload.js';

// 引入全局组件核销组件
import verifiCation from '@/components/verifiCation.vue';
// 注册全局组件核销订单组件
Vue.component('verifiCation', verifiCation);

// 引入路由实例对象
import router from "./router";
import { addDateRange} from "@/utils/ruoyi";
import { getConfigKey } from "@/api/config";
// 引入字体图标库
import "@/assets/icon/iconfont.css";

// 全局注册图片懒加载
Vue.directive('lazyLoad', lazyLoad);
// 全局方法挂载
Vue.prototype.getConfigKey = getConfigKey
Vue.prototype.addDateRange = addDateRange

Vue.use(ElementUI);
Vue.config.productionTip = false;
Vue.filter('toFixed2Filter', function(value){
  return parseFloat(value).toFixed(2);
});


let vueApp = new Vue({
  router,
  render: (h) => h(App)
}).$mount("#app");

Vue.prototype.$EventBus = vueApp

const isOpen = Vue.observable({
  success: false,
  warning:false
});

export default isOpen;
