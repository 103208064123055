import server from "../utils/printhttp.js";

export function executePrinting(data) {
    return server({
        url: '/print',
        method: 'post',
        data: data
    });
}





