<template>
  <div>
    <!-- 搜索框 -->
    <div class="block">
      <el-input
        style="margin-bottom: 10px; width: 280px"
        prefix-icon="el-icon-search"
        v-model.trim="searchValue"
        ref="searchInput"
        @keyup.enter.native="onSubmit()"
        @focus="handleFocus"
        @blur="handleBlur"
        placeholder="输入订单号搜索"
      />
      <el-button
        type="warning"
        style="margin-bottom: 10px; margin-left: 5px"
        @click="cancelOrder"
        class="btn"
        >搜索</el-button
      >
      <el-button
        type="danger"
        class="btn"
        style="margin-left: 10px;margin-bottom: 8px;"
        @click="clear"
        >清空</el-button
      >
    </div>
    <el-card class="card">
      <!-- 表格 -->
      <el-table  height="500" :data="list" stripe style="width: 100%">
        <el-table-column label="序号" width="48px"  fixed align="center">
          <template v-slot="{ $index }">
            {{ (query.pageNum - 1) * query.pageSize + ($index + 1) }}
          </template>
        </el-table-column>
        <el-table-column
          label="订单编号"
          width="190px"
           fixed
          align="left"
          prop="orderNumber"
        />
        <el-table-column
          label="订单金额"
          width="80px"
          align="center"
          prop="totalAmount"
        >
          <template v-slot="{ row }">
            ￥{{ row.totalAmount | toFixed2Filter }}
          </template>
        </el-table-column>
        <el-table-column
          label="积分抵扣金额"
          prop="pointsDeductionAmount"
          align="center"
          width="150px"
        >
          <template v-slot="{ row }">
            ￥{{ row.pointsDeductionAmount | toFixed2Filter }}
          </template>
        </el-table-column>
        <el-table-column
          label="实际支付"
          align="center"
          prop="paymentAmount"
        >
          <template v-slot="{ row }">
            ￥{{ row.paymentAmount | toFixed2Filter }}
          </template>
        </el-table-column>
        <el-table-column
          label="交易赠送积分"
          width="105px"
          align="center"
          prop="rewardPoints"
        />
        <el-table-column label="支付状态"  align="center" prop="paymentStatus">
          <template v-slot="{ row }">
            <el-tag type="danger" v-if="row.paymentStatus == -4">退款失败</el-tag>
            <el-tag type="danger" v-if="row.paymentStatus == -3">已退款</el-tag>
            <el-tag type="danger" v-if="row.paymentStatus == -2">转入退款</el-tag>
            <el-tag v-if="row.paymentStatus == -1">支付失败</el-tag>
            <el-tag type="info" v-if="row.paymentStatus == 0">未支付</el-tag>
            <el-tag type="warning" v-if="row.paymentStatus == 1">支付中</el-tag>
            <el-tag type="success" v-if="row.paymentStatus == 2">支付成功</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="订单状态" align="center" prop="orderStatus">
           <template v-slot="{ row }">
          <el-tag type="danger" v-if="row.orderStatus == -1">取消</el-tag>
          <el-tag type="info" v-if="row.orderStatus == 0">待处理</el-tag>
          <el-tag type="success" v-if="row.orderStatus == 1">备餐中</el-tag>
          <el-tag type="success" v-if="row.orderStatus == 2">已取餐</el-tag>
          <el-tag type="success" v-if="row.orderStatus == 3">订单完成</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          label="支付方式"
          align="center"
          width="100px"
          prop="paymentMethod"
        >
        <template v-slot="{ row }">
          <el-tag type="danger" v-if="row.paymentMethod == -1">未知</el-tag>
          <el-tag type="info" v-if="row.paymentMethod == 0">现金</el-tag>
          <el-tag type="success" v-if="row.paymentMethod == 1">小程序</el-tag>
          <el-tag v-if="row.paymentMethod == 2">付款码</el-tag>
          <el-tag type="warning" v-if="row.paymentMethod == 3">扫码</el-tag>
          <el-tag type="warning" v-if="row.paymentMethod == 4">会员余额</el-tag>
        </template>
        </el-table-column>
        <el-table-column
          label="支付时间"
          align="center"
          width="160px"
          prop="paymentTime"
        />
        <el-table-column fixed="right"  align="center" label="操作">
          <template v-slot="{ row }">
            <el-button type="text" style="font-size: 18px" @click="Pay(row)"
              >退款</el-button>
              <!-- <el-button type="text" v-if="row.orderStatus==1&&(row.printStatus==-1||row.printStatus==0)" style="font-size: 18px" @click="printOrder(row.id)"
              >打印小票</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页器 -->
      <div class="pagination">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-size="query.pageSize"
          layout="total, sizes, prev, pager, next"
          :total="total"
          el-pagination
        >
        </el-pagination>
      </div>
    </el-card>

    <el-dialog
      v-loading="loading"
      element-loading-text="退款中请稍后"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      width="45%"
      :visible="dialogVisible"
      :showClose="false"
    >
      <div class="payment-result">
        <h1>点餐用户订单退款</h1>
      </div>
      <el-result
        icon="success"
        :title="title"
        :subTitle="subTitle"
        v-if="status === 1"
      >
        <template slot="extra">
          <el-button type="primary" size="medium" @click="close"
            >返回</el-button
          >
        </template>
      </el-result>
      <el-result
        v-if="status === -1"
        icon="error"
        :title="title"
        :subTitle="subTitle"
      >
        <template slot="extra">
          <el-button type="primary" size="medium" @click="close"
            >返回</el-button
          >
        </template>
      </el-result>
      <div v-if="status === 0">
        <div class="text">
          <h4>应退款金额</h4>
          <p style="margin-left: 10px">
            ￥{{ orderInfo.paymentAmount | toFixed2Filter }}
          </p>
        </div>
        <div class="text">
          <h4>应退还积分</h4>
          <p style="margin-left: 10px">{{ orderInfo.deductionPoints }}</p>
        </div>
        <div class="text">
          <h4>应收回赠送积分</h4>
          <p style="margin-left: 10px">{{ orderInfo.rewardPoints }}</p>
        </div>

        <div class="btns">
          <el-button type="danger" round class="btn" @click="dialogVisible = false"
            >取 消</el-button
          >
          <el-button round class="btn" style="margin-left:20px" type="warning" @click="refund">确 定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import cache from "@/utils/cache";
import { getOrderList, getPay } from "../api/order";
import { executePrinting } from "@/api/printapi"
import {BASE_URL} from "@/config/config";
export default {
  name: "Order",
  data() {
    return {
      storeInfo: cache.session.getJSON("storeInfo") || {},
      list: [],
      query: {
        pageNum: 1,
        pageSize: 10,
      },
      storeid: 0,
      searchValue: "",
      currentPage4: 1,
      total: 0,
      loading: false,
      dialogVisible: false,
      orderInfo: {},
      status: 0,
      title: "",
      subTitle: "",
    };
  },

  created() {
    // console.log(this.isOpen.success);
    this.getList();
  },

  methods: {
    // 聚焦事件
      handleFocus(){
      this.$removeKeydownListener();
    },
     // 失焦事件
      handleBlur(){
      this.$addKeydownListener();
    },
    // 获取列表
    async getList() {
      const res = await getOrderList({
        pageNum: this.query.pageNum,
        pageSize: this.query.pageSize,
        storeId: this.storeInfo.id,
        searchValue: this.searchValue,
      });
      // console.log(res);
      this.list = res.rows;
      this.total = res.total;
    },
    // 搜索输入框的失焦和聚焦事件
    searchBlur() {
      this.$nextTick(()=> {
        if (this.$refs.searchInput) {
        // this.$refs.searchInput.focus();
        // this.$refs.searchInput.select();
        }
      });
    },
    //退款
    Pay(row) {
      // console.log(row);
      this.status = 0;
      this.orderInfo = row;
      this.id = row.id;
      this.dialogVisible = true;
    },
     printOrder(id) {
      //构建打印参数
      let printParams = {
        printType: 1,
        printerName: "POS58",
        dataUrl: BASE_URL + "/print/store/receipt/" + id,
        template: null,
        notifyUrl: BASE_URL + "/print/notify/" + 1 + "/" + id
      }
      executePrinting(printParams).then(response => {
        console.log(response);
      });
    },
    async refund() {
      this.loading = true;
      const res = await getPay(this.id);
      if(res.code == 200){
        this.status = 1;
        this.title = "退款成功";
        this.subTitle = "订单退款操作成功，资金将原路退回至用户支付账户，请关注退款通知。";
        this.loading = false;
      }else{
        this.status = -1;
        this.title = "退款失败";
        this.subTitle = res.msg;
        this.loading = false;
      }
    },
    onSubmit() {
      // console.log(1111);
      this.getList();
    },
    // 搜索事件
    cancelOrder() {
      this.getList();
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`)
      this.query.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.query.pageNum = val;
      this.getList();
    },
    close() {
      this.dialogVisible = false;
      this.getList()
    },
    // 重置
    clear() {
      this.query = {
        pageNum: 1,
        pageSize: 10,
      },
        this.searchValue = "",
        this.storeid = 0;
      this.getList();
    },
    // 关闭
    refundNotice(message) {
      console.log(JSON.stringify(message));
      if (message.status === -3) {
        this.loading = false;
        this.status = 1;
      } else {
        this.status = -1;
      }
    },
  },
};
</script>
<!-- 修改分页背景色 -->
<style>
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #c5ad76;
    color: #fff;
  }
</style>
<style scoped>
::v-deep .el-table__body-wrapper::-webkit-scrollbar{ 
  width: 0; 
  display: none;
}
::v-deep .el-table__fixed-right{
  right: 0px !important;
}
::v-deep .el-card__body{
  padding:0px 0px 20px 0px;
}
 ::v-deep.el-table .cell{
  line-height: 30px;
 }
.block {
  display: flex;
  align-items: center;  
}
.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: end;
  margin-right: 50px;
}
.card{
  height: 50%;
  overflow: scroll;
}
::v-deep .card::-webkit-scrollbar{ 
  width: 0; 
  display: none
}
.text {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btns {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
el-icon {
  display: none;
}
.payment-result{
  text-align: center;
}
.btn{
 width: 80px;
 height: 40px;
 text-align: center;
 align-items: center;
 /* font-size: 20px; */
 display: block;
}
::v-deep .no-number::-webkit-outer-spin-button,
.no-number::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none !important;
}
.no-number input[type="number"]::-webkit-outer-spin-button,
.no-number input[type="number"]::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none !important;
}
</style>
