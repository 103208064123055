import server from "../utils/http.js";
// 获取分类
export const getStatistics = storeId =>server.get(`product/category/findListByStoreId/${storeId}`);
// 获取列表
export const getList = data =>server.post('/store/statistics/product/sales/detail',data);


// 商品销售明细统计接口
// /store/statistics/product/sales/detail
// 请求方式：post
// 请求参数：
// storeId     门店ID   必填
// categoryId   分类ID   非必填
// startTime     开始时间   非必填
// endTime     结束时间   非必填

// 返回结果：jsonArray
// productName      商品名称
// salesQuantity       销售数量
// salesAmount        销售金额
// quantityProportion    销售数量占比
// amountProportion     销售金额占比