import server from "../utils/http.js";
// 门店查询分页查询菜品列表
export const getGoodsSpuList = params =>server.get("/product/goods/findStoreSpuList",{params});

// 查询SPU信息详细
export const getGoods = id =>server.get(`/product/goods/${id}`);
// 获取所属门店
export const getAllList = () =>server.get('/system/storeInfo/all');
// 获取分类类别
export const getAllCategory = () =>server.get('/platformdata/platformcategory/all');
// 修改SPU信息
export const updateGoods =(data)=>server.put('/product/goods',data)
// 删除sku
export const skuDel = id =>server.delete(`/product/goods/sku/${id}`)
// 商品同步更新接口：
export const goodsAsync = ids =>server.get(`product/goods/synchronous/${ids}`);
//可导入商品信息接口
export const getLeadCommodity = params =>server.get('/platformdata/platformspu/import/list',{ params })
// 确认导入
export const affirmLead = params =>server.post('/product/goods/import',params )
