<template>
  <div><!-- 支付 -->
  <el-dialog
    width="60%"
    append-to-body
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
    :show-close="false"
    class="verification"
  >
    <div v-if="operateStatus == 4" style="text-align: center;">
      <!-- <el-radio-group style="margin-top:5%" v-model="verification" fill="#E6A23C">
        <el-radio-button label="1">扫码核销</el-radio-button>
        <el-radio-button label="2">手动核销</el-radio-button>
      </el-radio-group> -->
      <!-- 自动核销 -->
      <div v-show="verification == 1" class="payment-result">
        <el-result
          v-show="this.writeOffStatus == 0"
          title="扫描订单核销码进行核销"
          subTitle="请用户在“诸葛小二”小程序中打开对应订单核销码进行扫码"
        >
          <template slot="icon">
            <img src="@/assets/images/scancode-1.png" width="120" />
          </template>
        </el-result>
        <el-result
          v-if="this.writeOffStatus == 1"
          icon="success"
          title="订单核销成功"
          subTitle="订单核销成功，请凭借订单小票取用餐品！"
        ></el-result>
        <el-result
          v-show="this.writeOffStatus == -1"
          icon="error"
          title="订单核销失败"
          :subTitle="this.writeOffErrorMsg"
        ></el-result>
      </div>
      <!-- 手动核销 -->
      <!-- <div v-show="verification == 2" class="payment-result">
        <div style="margin-bottom: 50px; margin-top: 50px">
          <el-input
            class="inputButton"
            clearable
            ref="inputButton"
            placeholder="请输入订单号"
            v-model="searchValue"
            @clear="getUserList"
          >
            <el-button slot="append" @click="getOrderList">查询</el-button>
          </el-input>
        </div>
        <template>
          <el-table
            v-if="this.Successful == 0 && this.orderList.length > 0"
            ref="multipleTable"
            :data="orderList"
            tooltip-effect="dark"
            style="width: 100%; margin-bottom: 40px"
            highlight-current-row
            @current-change="handleSelectionChange"
          >
            <el-table-column type="index" width="55"> </el-table-column>
            <el-table-column
              prop="orderNumber"
              width="220"
              label="订单号"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="paymentAmount"
              label="订单金额"
              show-overflow-tooltip
            >
              <template v-slot="{ row }">
                ￥{{ row.totalAmount | toFixed2Filter }}
              </template>
            </el-table-column>
            <el-table-column
              prop="paymentAmount"
              label="支付金额"
              show-overflow-tooltip
            >
              <template v-slot="{ row }">
                ￥{{ row.paymentAmount | toFixed2Filter }}
              </template>
            </el-table-column>
            <el-table-column prop="createTime" label="日期" width="180">
            </el-table-column>
          </el-table>
          <el-result
            v-if="this.Successful == 1"
            icon="success"
            title="订单核销成功"
            subTitle="订单核销成功，请凭借订单小票取用餐品！"
          ></el-result>
          <el-result
            v-show="this.Successful == -2"
            icon="error"
            title="订单查询失败"
            subTitle="失败原因：根据查询条件未查询到相关待核销订单"
          ></el-result>
          <el-result
            v-show="this.Successful == -3"
            icon="error"
            title="订单查询失败"
            subTitle="失败原因：请输入正确的订单号"
          ></el-result>
          <el-result
            v-show="this.Successful == -1"
            icon="error"
            title="订单核销失败"
            :subTitle="this.writeOffErrorMsg"
          ></el-result>
        </template>
      </div> -->
      <div slot="footer" class="dialog-footer">
        <el-button
          type="info"
          @click="pepayment"
          v-show="operateStatus == 3"
          round
          >重新支付</el-button
        >
        <el-button
          type="info"
          @click="closeWriteOffView"
          v-show="operateStatus == 4"
          round
          >返回点餐</el-button
        >
        <el-button
          type="warning"
          @click="confirmCancel"
          v-show="verification == 2 && showBtn"
          round
          >确认核销</el-button
        >
      </div>
    </div>
  </el-dialog>
    <div class="audio">
       <audio ref="audioPlayer" :src="mp3" controls></audio>
    </div>
  </div>
</template>

<script>
import { getCancelList } from "@/api/order";
import { printOrder } from "@/router/paymentsucce.js";
import { orderWriteOff,getSpeech} from "@/api/index";
import { BASE_URL } from "@/config/config";
import cache from "@/utils/cache";
export default {
  name: "verifiCation",
  data() {
    return {
      storeInfo: {},
      dialogFormVisible: false,
      barcodeValue: null,
      operateStatus: 1,
      writeOffStatus: 0,
      verification: 1,
      writeOffErrorMsg: "系统异常，请联系系统管理员！",
      searchValue: "",
      Successful: 0, // 核销状态
      orderList: [],
      showBtn: true,
      code: "",
      lastTime: null,
      cancelOrderNumber: "",
      mp3:'',
      apiUrl: BASE_URL,
    };
  },
  created() {
    let storeInfo = cache.session.getJSON("storeInfo");
    if (storeInfo) {
      this.storeInfo = storeInfo;
    }
  },
  methods: {
    openModal() {
      this.barcodeValue = null;
      this.dialogFormVisible = true;
      this.operateStatus = 4;
      this.writeOffStatus = 0;
      /*window.addEventListener("keydown", this.handKey);*/
    },
    getUserList() {
      this.Successful = 0;
    },
      // 选中核销的订单号
    handleSelectionChange(e) {
      // console.log(e);
      this.cancelOrderNumber = e.orderNumber;
    },
    // 手动核销的操作
    async getOrderList() {
      if (
        this.searchValue &&
        this.searchValue != "" &&
        this.searchValue.length >= 6
      ) {
        this.showBtn = true;
        this.Successful = 0;
        const res = await getCancelList({
          storeId: this.storeInfo.id,
          searchValue: this.searchValue,
        });
        // console.log(res);
        if (res.data.length > 0) {
          this.orderList = res.data;
        } else {
          // this.$message.error('暂无数据')
          this.Successful = -2;
        }
      } else {
        this.Successful = -3;
      }
    },
    // 确认核销
    async confirmCancel() {
      if (this.operateStatus === 4) {
        const writeOffCode = this.cancelOrderNumber;
        if (writeOffCode && writeOffCode != "") {
          const res = await orderWriteOff({ writeOffCode });
          if ((res.code = 200)) {
            this.Successful = 1;
            this.showBtn = false;
            this.orderList = [];
          } else if (res.code == "ECONNABORTED") {
            this.$notify.error({
              title: "提示",
              message: "网络请求超时",
              duration: 10000,
            });
          } else {
            this.Successful = -1;
          }
        }
      }
    },
    pepayment() {
      this.barcodeValue = null;
      this.operateStatus = 1;
    },
    closeWriteOffView() {
      this.verification = 1;
      this.Successful = 0;
      this.orderList = [];
      this.dialogFormVisible = false;
      this.operateStatus = 1;
      this.writeOffStatus = 0;
      this.writeOffErrorMsg = "系统异常，请联系系统管理员！";
      this.barcodeValue = null;
      this.code = "";
      window.removeEventListener("keydown", this.handKey);
    },
    handKey(e) {
      if (this.lastTime == null) {
        this.lastTime = new Date().getTime();
        this.code += this.dispose(e.key);
      } else {
        let nowTime = new Date().getTime();
        if (e.keyCode === 13 || e.key == "Enter") {
          if (nowTime - this.lastTime < 80 && this.code.length > 15) {
            //在这里请求支付接口
            this.barcodeHandle();
          }
          this.code = "";
        } else {
          if (nowTime - this.lastTime < 9) {
            this.code += this.dispose(e.key);
          } else {
            this.code = this.dispose(e.key);
          }
        }
        this.lastTime = nowTime;
      }
    },
    //扫码枪输入校验
    dispose(value) {
      value = value.toUpperCase();
      let reg = /^[A-E0-9]+$/;
      if (reg.test(value)) {
        return value;
      } else {
        return "";
      }
    },
    barcodeHandle() {
      if (this.operateStatus === 1) {
        if (this.paymentMethod === "MICRO_PAYMENT") {
          this.code = this.code.replace(/[^0-9ABCDE]/g, "");
          // this.confirmPayment();
        } else {
          this.code = "";
        }
      } else if (this.operateStatus === 4) {
        this.code = this.code.replace(/[^0-9ABCDE]/g, "");
        this.submitWriteOff();
      } else {
        this.code = "";
      }
    },
    // 扫码核销
    submitWriteOff() {
      if (this.operateStatus === 4) {
        const writeOffCode = this.code;
        if (writeOffCode && writeOffCode != "") {
          orderWriteOff({ writeOffCode }).then((response) => {
              if (response.code == 200) {
                printOrder(response.data);
                this.getSpeechList('020101000003')
                this.writeOffStatus = 1;
                setTimeout(() => {
                  this.dialogFormVisible = false;
                }, 3000);
              } else {
                this.writeOffStatus = -1;
                this.getSpeechList('020101000003')
                this.writeOffErrorMsg = response.msg;
              }
              this.code = "";
            })
            .catch((error) => {
              // console.log(error);
              if (error.code == "ECONNABORTED") {
                this.$message.error("网络请求超时");
                this.code = "";
              }
            });
        }
      }
    },
    async getSpeechList(speechCode) {
      const res = await getSpeech(speechCode);
      // console.log(res);
      if (res.code == 200) {
        const audioPlayer = this.$refs.audioPlayer;
        this.mp3 = this.apiUrl + res.data.speechPath;  
        audioPlayer.onloadeddata = () => {
          audioPlayer.play(); // 等待音频加载完成后播放
        };
        audioPlayer.load(); // 先加载音频源
      }
    }
  },
};
</script>

<style scoped>
::v-deep .payment-result .el-result .el-result__title p {
  color: #f56c6c;
}
.payment-result {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.inputButton {
  width: 420px;
  height: 35px;
}
.dialog-footer {
  text-align: center;
  padding-bottom: 20px;
}
.verification{
  margin-top: -3%;
}
.audio{
  display: none;
}
</style>