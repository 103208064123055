export default {
  inserted: (el) => {
    const loadImage = () => {
      el.src = el.dataset.src;
    };

    const callback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          loadImage();
          observer.unobserve(el);
        }
      });
    };

    const observer = new IntersectionObserver(callback);
    observer.observe(el);
  }
};