import axios from 'axios';
import {PRINT_BASE_URL} from "@/config/config";

const printhttp = axios.create({
    baseURL: PRINT_BASE_URL,
    timeout: 1000 * 60,
    responseType: 'json',
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Cache-Control': 'no-cache'
    }
});

printhttp.interceptors.request.use(
    config => {
        // if(getToken()) {
        //     config.headers['Authorization'] = 'Bearer ' + getToken() //让每个请求携带自定义token
        // }
        return config;
    },
    error =>{
        return Promise.reject(error);
    }
);

printhttp.interceptors.response.use(
    response =>{
        return response.data;
    },
    error =>{
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    break;
                case 403:
                    break;
                case 404:
                    break;
                default:
                    break;
            }
        }
        return Promise.reject(error);
    }
);

export default printhttp;
