import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
//引入组件
import Main from "@/views/Main";
import Login from "@/views/Login";
import About from "@/views/About";
import Order from "@/views/Order";
import Setings from "@/views/Setings";
import Personnel from  '@/views/Personnel';
import Home from "@/views/Home";
import Statistics from "@/views/Statistics";
import ShoppingCart from "@/views/ShoppingCart";
import Console from "@/views/Console";
import TestHttp from "@/components/TestHttp";

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
//创建并暴露一个路由实例对象
const router = new VueRouter({
    routes: [
        {
            path: "/",
            // component: Main,
            component: () => import('@/views/Main'),
            redirect: "home",
            children:[
                {
                    path:'home',
                    // component:Home,
                    component: () => import('@/views/Home'),
                    redirect: "home/console",
                    children:[
                        {
                            path:'console',
                            // component:Console
                            component: () => import('@/views/Console'),
                        },
                        {
                            path:'statistics',
                            // component:Statistics
                            component: () => import('@/views/Statistics'),
                        },
                        {
                            path:'about',
                            // component:About
                            component: () => import('@/views/About'),
                        },
                        {
                            path:'order',
                            // component:Order
                            component: () => import('@/views/Order'),
                        },
                        {
                            path:'setings',
                            // component:Setings
                            component: () => import('@/views/Setings'),
                        },
                        {
                            path:'personnel',
                            // component:Personnel
                            component: () => import('@/views/Personnel'),
                        },
                        {
                            path:'userinfo',
                            // component:userinfo
                            component: () => import('@/views/UserInfo'),
                        },
                        {
                            path:'member',
                            // component:ShoppingCart
                            component: () => import('@/views/Member'),
                        },
                        {
                            path:'qrcodes',
                            // component:ShoppingCart
                            component: () => import('@/views/qrCodes'),
                        },
                        {
                            path:'recharge',
                            // component:ShoppingCart
                            component: () => import('@/views/recharge'),
                        },
                        {
                            path:'refund',
                            // component:ShoppingCart
                            component: () => import('@/views/refund'),
                        }
                    ]
                },
                {
                    path:'shoppingCart',
                    // component:ShoppingCart
                    component: () => import('@/views/ShoppingCart'),
                },
                {
                    path:'TouristOrder',
                    component: () => import('@/views/TouristOrder'),
                },
                {
                    path:'customerOrder',
                    component: () => import('@/views/customerOrder'),
                }
            ]
        },
        {
            path: "/login",
            component: Login
        },
        {
            path: "/send",
            component: TestHttp
        }
    ],
    mode: 'history'
});
export default router