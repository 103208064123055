import {getSpeech} from "@/api/index";

export async function playSpeech(speechCode, apiUrl, data) {
  const res = await getSpeech(speechCode);
  if (res.code === 200) {
    const audioPlayer = new Audio();
    data.mp3 = apiUrl + res.data.speechPath;  // 更新 mp3 属性的值
    audioPlayer.src = data.mp3;
    audioPlayer.onloadeddata = () => {
      audioPlayer.play();
    };
    audioPlayer.load();
  }
}