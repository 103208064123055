<template>
  <el-row class="main-el-row">
    <el-col :span="4" class="left-view">
      <div class="left-main">
        <el-row>
          <el-col :span="24" class="logo-img">
            <img src="@/assets/images/logo-2.png" />
          </el-col>
        </el-row>
        <div class="left-menu-first">
          <el-row>
            <el-col :span="12">
              <div @click="getToMember">
                <el-button>
                  <i class="el-icon-user-solid"></i>
                  <br />
                  <label>会员</label>
                </el-button>
              </div>
            </el-col>
            <el-col :span="12" style="margin-left:20px">
              <div @click="getOrderForm">
                <el-button>
                  <i class="el-icon-s-order"></i>
                  <br />
                  <label>订单</label>
                </el-button>
              </div>
            </el-col>
          </el-row>
        </div>
          <el-row class="left-menu-end">
          <el-col :span="24">
            <div @click="getToOrder">
              <el-button>
                <i class="el-icon-takeaway-box"></i>
                <br />
                <label>开始点餐</label>
              </el-button>
            </div>
          </el-col>
        </el-row>
          <el-row :gutter="16" class="left-menu" style="margin-top:10px">
          <el-col :span="12">
            <div @click="getToHome">
              <el-button icon="icon-home iconfont">主页</el-button>
            </div>
          </el-col>
          <el-col :span="12">
            <div @click="goToAbout">
              <el-button icon="el-icon-tableware">菜品</el-button>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="16" class="left-menu">
          <el-col :span="12">
            <div @click="getStatistics">
              <!-- to="/home/statistics" -->
              <el-button icon="el-icon-s-data">统计</el-button>
            </div>
          </el-col>
          <el-col :span="12">
            <router-link to="/home/personnel">
              <el-button icon="el-icon-user-solid">人员</el-button>
            </router-link>
          </el-col>
        </el-row>
          <el-row :gutter="16" class="left-menu">
          <el-col :span="12">
             <router-link to="/home/recharge">
             <!-- icon="el-icon-coin" -->
              <el-button >充值记录</el-button>
             </router-link>
          </el-col>
          <el-col :span="12">
            <router-link to="/home/refund">
             <!-- icon="el-icon-s-finance" -->
              <el-button>退款记录</el-button>
            </router-link>
          </el-col>
        </el-row>
      </div>
    </el-col>
    <el-col :span="20" class="right-view">
      <div class="right-view-top">
        <el-menu mode="horizontal">
          <el-menu-item
            >诸葛小二&nbsp;-&nbsp;{{
              this.storeInfo.storeName
            }}收银工作台</el-menu-item>
            <el-menu-item class="back">
               <el-button @click="goCustomOrder" type="danger" size="medium" round>游客模式</el-button>
            </el-menu-item>
          <el-menu-item class="top-menu-right">
            <el-dropdown trigger="click" @command="handleCommand">
              <span class="el-dropdown-link">
                {{ this.userInfo.userName }}({{ this.userInfo.nickName }})
                <el-avatar size="small" :src="logo"></el-avatar>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="/home/setings">
                  系统配置
                </el-dropdown-item>
                <el-dropdown-item command="/home/UserInfo">
                  个人信息
                </el-dropdown-item>
                <!-- <el-dropdown-item command="/home/qrCodes">
                  扫码
                </el-dropdown-item> -->
                <el-dropdown-item @click.native="logout">
                  退出登录
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-menu-item>
        </el-menu>
      </div>
      <vue-scroll :ops="ops">
        <!-- <el-alert title="系统公告：XXXXX.XXXX.XXXX" type="warning"> </el-alert> -->
        <div class="right-main">
          <router-view ref="child"></router-view>
        </div>
      </vue-scroll>
    </el-col>
  </el-row>
</template>
<script>
import logo from "@/assets/logo.png";
import vueScroll from "vuescroll";
import cache from "@/utils/cache";
import { BASE_URL } from "@/config/config";
import { logout } from "@/api";
import { removeToken } from "@/utils/auth";
export default {
  components: { vueScroll },
  name: "Home",
  data() {
    return {
      logo: logo,
      apiUrl: BASE_URL,
      userInfo: cache.session.getJSON("userInfo") || {},
      storeInfo: cache.session.getJSON("storeInfo") || {},
      ops: {
        vuescroll: {},
        scrollPanel: {
          initialScrollY: 0, //只要组件mounted之后自动滚动的距离。 例如 100 or 10%
          initialScrollX: 0,
          scrollingX: false,
          scrollingY: true,
        },
        rail: {
          keepShow: true,
        },
        bar: {
          hoverStyle: true,
          onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
          background: "#cdb684", //滚动条颜色
          opacity: 0.5, //滚动条透明度
          "overflow-x": "hidden",
        },
      },
    };
  },
  mounted() {
    history.pushState(null, null, document.URL);
    window.addEventListener(
      "popstate",
      function (e) {
        history.pushState(null, null, document.URL);
      },
      false
    );
  },
  created() {

  },
  methods: {
    handleCommand(command) {
      if (command === "/login") {
        logout().then((response) => {
          if (response.code === 200) {
            removeToken();
            cache.session.remove("userInfo");
            cache.session.remove("storeInfo");
            cache.local.remove("userid");
            cache.session.remove("userid");
            this.$router.push("/login");
          }
        });
      } else {
        this.$router.push(command);
      }
    },
    getToMember() {
      this.$router.push({ path: "/home/Member" });
    },
    getToOrder() {
      // console.log(11);
      this.$router.push({ path: "/shoppingCart" });
    },
    goToAbout() {
      this.$router.push({ path: "/home/about" });
    },
    getOrderForm() {
      this.$router.push({ path: "/home/order" });
    },
    getStatistics() {
      this.$router.push({ path: "/home/statistics" });
    },
    getToHome(){
      this.$router.push({ path: "/home" });
    },
    logout() {
      //  await this.$router.push('/login')
      this.$router.push(`/login?redirect=${this.$route.fullPath}`);
      if (document.webkitFullscreenElement) {
        document.webkitExitFullscreen();
      }
      return;
    },
    goCustomOrder(){
       this.$router.push({ path: "/customerOrder" });
    },
    // 退款通知
    refundNotice(message) {
      try {
        this.$refs.child.refundNotice(message);
      } catch (err) {
        console.log("子页面没有refundNotice()函数");
      }
    },
    // 支付通知
    paymentNotice(paymentStatus) {
      // console.log('home',paymentStatus, this.$refs.child)
       try {
        this.$refs.child.paymentNotice(paymentStatus);
      } catch (err) {
        console.log("子页面没有refundNotice()函数");
      }
    },
    // 关注通知
    bindSUccess(paymentStatus) {
      // console.log('home',paymentStatus, this.$refs.child)
       try {
        this.$refs.child.bindSUccess(paymentStatus);
      } catch (err) {
        console.log("子页面没有refundNotice()函数");
      }
    },

  },
};
</script>

<style scoped>
.main-el-row {
  height: 100vh;
  overflow: hidden;
}
.el-row{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.left-view {
  height: 100%;
  background: linear-gradient(to bottom right, #213443, #2d4050);
}
.right-view {
  height: 100%;
  background-color: white;
}

.left-main {
  padding: 20px;
  height: 100vh;
}
.logo-img {
  padding: 60px 40px;
}
.logo-img img {
  width: 100%;
  border: 0px;
  opacity: 0.8;
}
.left-menu {
  padding: 8px 2px;
  text-align: center;
}
.left-menu button {
  width: 100%;
  height: 46px;
  color: aliceblue;
  background-color: #394a54;
  border: 1px solid #364751;
}
.left-menu-end {
  padding: 0px;
  padding-bottom:5px ;
}
.left-menu-end button {
  width: 100%;
  height: 120px;
  color: aliceblue;
  line-height: 30px;
  background: linear-gradient(to bottom, #cdb684, #b49a55);
  margin-top: 20px;
  border: 1px solid #364751;
}
.left-menu-first button {
  width: 100%;
  height: 80px;
  color: aliceblue;
  line-height: 30px;
  background: linear-gradient(to bottom, #f3c76e, #caa25d);
  margin-top: 10px;
  border: 1px solid #364751;
}
.left-menu-end button i {
  font-size: 30px;
}
.right-main {
  padding: 20px;
}
.top-menu-right {
  float: right;
}
::v-deep .iconfont {
  font-size: 14px !important;
}
::v-deep .el-menu--horizontal > .el-menu-item {
  height: 80px;
  line-height: 80px;
}
::v-deep .el-dropdown-menu__item {
  line-height: 50px;
}
::v-deep .el-dropdown-menu__item {
  font-size: 16px;
}
</style>
