import axios from "axios";
import router from '@/router'
import { BASE_URL } from "@/config/config";
import { getToken,removeToken } from "@/utils/auth";
import { Message } from 'element-ui'
const http = axios.create({
  baseURL: BASE_URL,
  timeout: 1000 * 5,
  responseType: "json",
  withCredentials: true,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    "Cache-Control": "no-cache",
  },
});

http.interceptors.request.use(
  (config) => {
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false;
    if (getToken() && !isToken) {
      config.headers["Authorization"] = "Bearer " + getToken(); //让每个请求携带自定义token
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => {
    // console.log(response);
    if (response.data.code === 401) {
    //   Message.error("请重新登录");
      // 清除token
      removeToken()
      // 转跳登录页
      router.push("/login");
    } else if(response.data.code ==403){
      Message.error("暂无权限，请联系管理员");
    }
    return response.data;
  },
  (error) => {
    if (error.response && (error.response.status === 408 || error.response.status === 504)) {
      // 处理408或504错误的逻辑，例如重新请求、提示用户等
    }
    return Promise.reject(error);
  }
);

export default http;
