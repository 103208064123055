<template>
  <div>
    <el-card>
      <div class="head">
        <el-select v-model="categoryCode" @change="change" placeholder="请选择">
          <el-option
            v-for="item in list"
            :key="item.id"
            :label="item.categoryName"
            :value="item.categoryCode"
          >
          </el-option>
        </el-select>
         <div class="block">
           <!-- <span class="demonstration">起始日期时刻为 12:00:00，结束日期时刻为 08:00:00</span> -->
            <el-date-picker
              v-model="timeValue"
              type="datetimerange"
              align="right"
               value-format="yyyy-MM-dd HH:mm:ss"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="gettime"
              :default-time="['00:00:00', '23:59:59']"
              >
            </el-date-picker>
          </div>
        <div class="block">
        <el-button type="warning" class="btn" style="margin-left: 10px" @click="search"
          >检索</el-button
        >
         <el-button type="danger" class="btn" style="margin-left: 10px" @click="empty"
          >清空</el-button
        >
      </div> 
      </div>
      <template>
        <el-table
          size="medium"
          :data="tabsList"
          style="width: 100%; "
          height="500"
        >
          <el-table-column
            fixed
            label="序号"
            align="center"
            width="80"
            type="index"
          >
          </el-table-column>
          <el-table-column
            prop="productName"
            label="商品名称"
            align="left"
            width="280"
          >
          </el-table-column>
          <el-table-column
            prop="salesQuantity"
            align="center"
            label="销售数量(份)"
          >
          </el-table-column>
          <el-table-column prop="salesAmount" align="center" label="销售金额">
            <template v-slot="{ row }">
              ￥{{ row.salesAmount | toFixed2Filter }}
            </template>
          </el-table-column>
          <el-table-column prop="saleAveragePrice" align="center" label="销售均价">
            <template v-slot="{ row }">
              ￥{{ row.saleAveragePrice | toFixed2Filter }}
            </template>
          </el-table-column>
          <el-table-column
            prop="quantityProportion"
            label="销售数量占比"
            align="center"
          >
            <template v-slot="{ row }">
              {{ row.quantityProportion }}%
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="amountProportion"
            label="销售金额占比"
          >
            <template v-slot="{ row }"> {{ row.amountProportion }}% </template>
          </el-table-column>
        </el-table>
      </template>
    </el-card>
    <!-- <verifiCation ref="verifiCation"></verifiCation> -->
  </div>
</template>

<script>
import cache from "@/utils/cache";
import { getStatistics, getList } from "@/api/statistics";
export default {
  name: "Statistics",
  data() {
    return {
      storeInfo: cache.session.getJSON("storeInfo") || {},
      list: [],
      tabsList: [],
      categoryCode: "",
      startTime: "",
      endTime: "",
      timeValue:''
    };
  },
  mounted() {
    history.pushState(null, null, document.URL);
    window.addEventListener(
      "popstate",
      function (e) {
        history.pushState(null, null, document.URL);
      },
      false
    );
  },
  created() {
    this.getStatisticsList();
    this.getList();
  },
  methods: {
    async getStatisticsList() {
      const res = await getStatistics(this.storeInfo.id);
      // console.log(res);
      if (res.code == 200) {
        this.list = res.data;
      }
      // console.log(this.value);
    },
    search() {
      // console.log(this.value);
      this.getList();
    },
    async getList() {
      const res = await getList({
        storeId: this.storeInfo.id,
        categoryCode: this.categoryCode,
        startTime:this.startTime,
        endTime:this.endTime,

      });
      // console.log(res);
      this.tabsList = res.data;
    },
     gettime(value1) {
        this.startTime = value1[0];
        this.endTime = value1[1];
        this.search()
      // console.log('开始事件:',this.startTime,'结束时间:',this.endTime)
    },
    empty(){
      this.startTime = '',
      this.endTime = '',
      this.timeValue=''
      this.categoryCode = ''
      this.getList();
    },
    change(e){
      // console.log(e);
      this.categoryCode = e
      this.getList();

    }
  },
};
</script>

<style scoped>
::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 0;
  display: none;
}
 ::v-deep.el-table .cell{
  line-height: 40px;
  font-size: 15px;
 }
.head{
  display: flex;
  margin-bottom: 20px;
}
.block{
  margin-left: 10px;
}
.btn{
 width: 80px;
 height: 40px;
 text-align: center;
 align-items: center;
 /* font-size: 20px; */
 /* display: block; */
}
</style>
