<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import Vue from 'vue';
export default {
  name: "App",
  data() {
    return {
      lastTime: null,
      code:'',
    }; 
  },
  mounted() {  
    // 监听扫码枪事件
    window.addEventListener("keydown", this.handKey);
    history.pushState(null, null, document.URL);
    window.addEventListener("popstate",function(e) {
      history.pushState(null, null, document.URL);
    }, false);
  },
   created() {
     Vue.prototype.$removeKeydownListener = this.removeKeydownListener;
     Vue.prototype.$addKeydownListener = this.addKeydownListener;
  },
  methods: {
    handKey(e) {
      if (this.lastTime == null) {
        this.lastTime = new Date().getTime();
        this.code += this.dispose(e.key);
      } else {
        let nowTime = new Date().getTime();
        if (e.keyCode === 13 || e.key == "Enter") {
          if (nowTime - this.lastTime < 80 && this.code.length > 15) {
            this.$EventBus.$emit("smq", this.code,'123');
          }
          this.code = "";
        } else {
          if (nowTime - this.lastTime < 9) {
            this.code += this.dispose(e.key);
          } else {
            this.code = this.dispose(e.key);
          }
        }
        this.lastTime = nowTime;
      }
    },
    //扫码枪输入校验
    dispose(value) {
      value = value.toUpperCase();
      let reg = /^[A-E0-9]+$/;
      if (reg.test(value)) {
        return value;
      } else {
        return "";
      }
    },
    removeKeydownListener() {
      // console.log('移除监听');
      window.removeEventListener("keydown", this.handKey);
    },
    addKeydownListener(){
      // console.log('添加监听');
      window.addEventListener("keydown", this.handKey);
    }
  }
}
</script>

<style>
html,body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow: hidden;
}
#app{
  height: 100%;
  overflow: hidden;
}
</style>