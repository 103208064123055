import server from "../utils/http.js";

export const getCodeImg = () => {
    return server({
        url: '/captchaImage',
        headers: {
            isToken: false
        },
        method: 'get',
        timeout: 20000
    })
}

export const login = (data) => {
    return server({
        url: '/login',
        headers: {
            isToken: false
        },
        method: 'post',
        data: data
    })
}

export const getUserInfo = () => {
    return server({
        url: '/getInfo',
        method: 'get'
    })
}

export function getProducts(query) {
    return server({
        url: '/product/goods/findByCategory',
        method: 'get',
        params: query
    })
}

export function productSearch(query) {
    return server({
        url: '/product/goods/search',
        method: 'post',
        params: query
    })
}

export function getOrderNumber(query) {
    return server({
        url: '/order/food/number',
        method: 'get',
        params: query
    })
}


export function addOrder(params) {
    return server({
        url: '/order/food/storeAdd',
        method: 'post',
        data: params
    })
}


export function cancelOrder(params) {
    return server({
        url: '/order/food/cancelOrder',
        method: 'get',
        params: params
    })
}

export function checkOrderEdit(params) {
    return server({
        url: '/order/food/checkOrderEdit',
        method: 'get',
        params: params
    })
}


export function storePayment(params) {
    return server({
        url: '/order/food/storePayment',
        method: 'post',
        data: params
    })
}

export function orderWriteOff(params) {
    return server({
        url: '/order/food/store/writeOff',
        method: 'post',
        data: params
    })
}


export function logout() {
    return server({
        url: '/logout',
        method: 'post'
    })
}

// 获取库存
export const getInventory = params => server.get(`/product/goods/inventory/${params.spuId}/${params.skuId}`)
// 门店查询会员优惠信息接口：
export const getDiscounts = params => server.post('/order/food/discounts/',params)
// 门店根据手机号查询客户信息接口：
// /custom/{phoneNumber}
export const getPhoneNumber = phoneNumber => server.get(`/member/${phoneNumber}`)
// 订单支付状态查询接口：
export const getPaymentStatus = orderId => server.get(`/order/food/payment/status/${orderId}`)
// 查询可补打印小票订单信息接口
export const getPrints = storeId => server.get(`/order/food/prints/${storeId}`)
// 收银端根据语音提示信息编号查询语音提示信息接口：
// /system/speech/{speechCode}
export const getSpeech = speechCode => server.get(`/system/speech/${speechCode}`)





