import server from "../utils/http.js";
import { parseStrEmpty } from "@/utils/ruoyi";
// 获取用户个人信息
export const getUserInfo = ()=>server.get('/system/user/profile');
// 修改用户个人性息
export const updateUserProfile = (params) =>server.put('/system/user/profile',params);
// 修改密码
export const updateUserPwd = params =>server.put(`/system/user/profile/updatePwd?oldPassword=${params.oldPassword}&newPassword=${params.newPassword}`);
// 人员查询用户列表
export function listUser(query) {
  return server({
    url: '/system/user/list',
    method: 'get',
    params: query
  })
}
// 用户状态修改
export const changeUserStatus = (userId, status) => server.put('/system/user/changeStatus', { userId, status });
// 查询用户详情
export const  getUser = userId => server.get('/system/user/' + parseStrEmpty(userId))
// 查询部门下拉树结构
export const deptTreeSelect = () => server.get('/system/user/deptTree')
// 新增用户
export const addUser = data =>server.post('/system/user',data)
//修改用户
export const updateUser = data =>server.put('/system/user',data)
// 删除
export const delUser = userId =>server.delete('/system/user/'+ userId)
// 用户重置密码
export const resetUserPwd = (userId,status) => server.put('/system/user/resetPwd',{userId,status})




