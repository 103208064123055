<template>
  <div>
    <router-view ref="child"></router-view>
       <verifiCation ref="verifiCation"></verifiCation>
  </div>
</template>
<script>
import cache from "@/utils/cache";
import { executePrinting } from "@/api/printapi";
import Stomp from "stompjs";
import {
  BASE_URL,
  MQTT_PASSWORD,
  MQTT_SERVICE,
  MQTT_USERNAME,
} from "@/config/config";
import isOpen from "@/main";
import { smqMixins } from "@/mixins/smqMixins.js";
export default {
  mixins: [smqMixins],
  data() {
    return {
      client: Stomp.client(MQTT_SERVICE),
      subscribe: null,
      storeInfo: cache.session.getJSON("storeInfo"),
      isOpen,
      reconnectCount: 1,
    };
  },
  created() {
    // this.getUserInfo();
  },
  mounted() {
    this.connectMQ();
    this.noBackward();
  },
  methods: {
    paymentNotice(paymentStatus) {
      // console.log('main+++++++++++++++++++++++,',paymentStatus)
      try {
        this.$refs.child.paymentNotice(paymentStatus);
      } catch (err) {
        console.log("没有相关子页面函数");
      }
    },
    bindSUccess(paymentStatus){
      try {
        this.$refs.child.bindSUccess(paymentStatus);
      } catch (err) {
        console.log("没有相关子页面函数");
      }
    },
    test() {
      try {
        this.$refs.child.test();
      } catch (err) {
        console.log("子页面没有test方法");
      }
    },
    noBackward() {
      history.pushState(null, null, document.URL);
      window.addEventListener(
        "popstate",
        function (e) {
          history.pushState(null, null, document.URL);
        },
        false
      );
    },
    connectMQ: function () {
      //初始化mqtt客户端，并连接mqtt服务
      if (!this.client) {
        this.client = Stomp.client(MQTT_SERVICE);
      }
      if (!this.client.connected) {
        const headers = {
          login: MQTT_USERNAME,
          passcode: MQTT_PASSWORD,
        };
        this.client.debug = false;
        this.client.heartbeat.outgoing = 5000; //设置发出心跳的时间间隔(毫秒)
        this.client.heartbeat.incoming = 5000; //设置接收心跳的时间间隔(毫秒)
        this.client.connect(headers, this.onConnected, this.onFailed);
      }
    },
    onConnected: function () {
      //订阅频道
      // console.log('门店信息',this.storeInfo);
      this.reconnectCount= 1
      if (this.storeInfo && this.storeInfo.id) {
        if (!this.subscribe) {
          this.subscribe = this.client.subscribe(
            "/exchange/cashier/" + this.storeInfo.id,
            this.callback,
            this.onFailed
          );
        }
      } else {
        this.$router.push("/login");
      }
    },
    onFailed: function (frame) {
      let that = this;
      if (!that.reconnectCount) {
        that.reconnectCount = 1;
      } else {
        that.reconnectCount++;
      }
      console.log("MQ Failed: " + frame);
      //连接失败重连
      if (that.reconnectCount <= 20) {
        //失败重连
        setTimeout(() => {
          that.client = null; //一个实例只能connect一次，得new个新的实例才可能进行新的connect
          that.connectMQ();
        }, 5000);
      } else {
        this.$notify.error({
          title: "错误",
          message: "网络连接失败，请重新登录，并检查网络状态",
          position: 'bottom-right',
          duration: 0,
        });
        this.$router.push("/login");
      }
    },
    callback: function (frame) {
      //接收消息处理
      let message = JSON.parse(frame.body);
      console.log("----------------------------message" , message);
      if (message.messageType == 1) {
        //订单打印
        if (message.status == 2) {
          // console.log('+++++++++++++++++++++++++++++++++++++');
          this.printOrder(message.id, "POS58");
          // this.printOrder(message.id, "RONGTARP58");
        }
        this.paymentNotice(message.status);
      } else if (message.messageType == 2) {
        if (message.status == 2) {
          this.printOrder(message.id, "POS58");
          // this.printOrder(message.id, "RONGTARP58");
        }
      } else if (message.messageType == 3) {
        this.paymentNotice(message.status);
      } else if (message.messageType == 7) {
        //会员页面支付回调
        this.paymentNotice(message.status);
      }else if (message.messageType == 8) {
        this.bindSUccess(message.status);
      }
    },
    printOrder(id, printerName) {
      //构建打印参数
      let printParams = {
        printType: 1,
        printerName: printerName,
        // printerName: "RONGTARP58",
        // printerName: "POS58",
        // printerName: "Microsoft Print to PDF",
        dataUrl: BASE_URL + "/print/store/receipt/" + id,
        template: null,
        notifyUrl: BASE_URL + "/print/notify/" + 1 + "/" + id,
      };
      executePrinting(printParams).then((response) => {
        // console.log(response);
      });
    },
    // 厨房小票打印机
    testPrint(){
    //   //构建打印参数
    //   let printParams = {
    //     printType: 1,
    //     // printerName: printerName,
    //     // printerName: "RONGTARP58",
    //     printerName: "RONGTARP58",
    //     // printerName: "Microsoft Print to PDF",
    //     dataUrl: BASE_URL + "/print/store/receipt/1",
    //     template: null,
    //     notifyUrl: BASE_URL + "/print/notify/" + 1 + "/1"
    //   }
    //   executePrinting(printParams).then();
    },
  },
  destroyed() {
    if (this.subscribe) {
      this.subscribe.unsubscribe();
      this.subscribe = null;
    }
    if (this.client && this.client.connected) {
      this.client.disconnect();
      this.client = null;
    }
  },
};
</script>