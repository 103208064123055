<template>
  <div class="box">
    <div class="block">
      <div>
        <el-button-group>
          <el-button @click="updateStatisticsData" icon="el-icon-refresh" :loading="loading">刷新数据</el-button>
          <el-date-picker
              v-model="dataTime"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :disabled=true>
          </el-date-picker>
        </el-button-group>
      </div>
      <div>
        <el-button-group>
          <el-button v-for="(item, index) in radioButton" :key="index" :type="item.buttonType" @click="buttonEvent(item)">{{ item.name }}</el-button>
        </el-button-group>
        <el-date-picker
            ref="datePicker"
            size="large"
            v-model="dataTime"
            value-format="yyyy-MM-dd"
            type="daterange"
            align="right"
            @change="selectDate"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            class="pickerTime"/>
      </div>
    </div>
    <div>
      <el-row :gutter="20" style="margin-bottom: 20px">
        <el-col :span="12">
          <el-card class="box-card" shadow="hover">
            <div>
              <el-row :gutter="10">
                <el-col :span="16"><h3><i class="el-icon-s-data"></i>&nbsp;交易订单总额(含退款)</h3></el-col>
                <el-col :span="8" class="text" style="text-align: right;">共&nbsp;{{ sale.orderTotalQuantity || 0 }}&nbsp;笔</el-col>
              </el-row>
            </div>
            <h3 style="color: #e6a23c; font-family: Arial; font-size: 36px; text-align: center; line-height: 120px;">
              ￥{{ sale.orderTotalAmount || 0.00 | toFixed2Filter }}
            </h3>
            <div class="text">
              <el-row>
                <el-col :span="12">退款&nbsp;{{ sale.refundOrderQuantity || 0 }}&nbsp;笔</el-col>
                <el-col :span="12" style="text-align: right">退款订单金额:&nbsp;￥{{ sale.refundOrderAmount || 0.00 | toFixed2Filter }}</el-col>
              </el-row>
            </div>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card class="box-card" shadow="hover">
            <div>
              <el-row :gutter="10">
                <el-col :span="16"><h3><i class="el-icon-s-data"></i>&nbsp;订单收入总额(不含退款)</h3></el-col>
                <el-col :span="8" class="text" style="text-align: right;">共&nbsp;{{ sale.orderQuantity || 0 }}&nbsp;笔</el-col>
              </el-row>
            </div>
            <h3 style="color: #e6a23c; font-family: Arial; font-size: 36px; text-align: center; line-height: 120px;">
              ￥{{ sale.orderAmount || 0.00 | toFixed2Filter }}
            </h3>
            <div class="text">
              <el-row>
                <el-col :span="12">优惠金额：&nbsp;￥{{ sale.discountAmount || 0.00 | toFixed2Filter }}</el-col>
                <el-col :span="12" style="text-align: right">应付金额：&nbsp;￥{{ sale.paymentAmount || 0.00 | toFixed2Filter }}</el-col>
              </el-row>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-bottom: 20px">
            <el-popover
            placement="right"
            width="450"
            trigger="click"
            >
            <el-table :data="gridData">
              <el-table-column align="center" property="payment" label="支付方式"/>
              <el-table-column align="center" property="money" label="支付金额">
                 <template v-slot='{row}'>
                    <span>￥{{ row.money | toFixed2Filter }}</span>
                 </template>
              </el-table-column>
              <el-table-column align="center" property="deal" label="交易笔数"></el-table-column>
            </el-table>
        <el-col :span="12" slot="reference">
          <el-card  class="box-card" shadow="hover">
            <div>
              <el-row :gutter="10">
                <el-col :span="16"><h3><i class="el-icon-s-data"></i>&nbsp;支付总额</h3></el-col>
                <el-col :span="8" class="text" style="text-align: right;">共&nbsp;{{ sale.orderQuantity || 0 }}&nbsp;笔</el-col>
              </el-row>
            </div>
            <h3 style="color: #e6a23c; font-family: Arial; font-size: 36px; text-align: center; line-height: 120px;">
              ￥{{ sale.paymentAmount || 0.00 | toFixed2Filter }}
            </h3>
            <div class="text">
              <el-row>
                <el-col :span="12">门店订单：&nbsp;￥{{ sale.mendianPaymentAmount || 0.00 | toFixed2Filter }}</el-col>
                <el-col :span="12" style="text-align: right">小程序订单：&nbsp;￥{{ sale.xiaochengxuPaymentAmount || 0.00 | toFixed2Filter }}</el-col>
              </el-row>
            </div>
          </el-card>
        </el-col>
          </el-popover>
        <el-col :span="12">
          <el-card class="box-card" shadow="hover">
            <div>
              <el-row :gutter="10">
                <el-col :span="16"><h3><i class="el-icon-s-data"></i>&nbsp;退款订单总额</h3></el-col>
                <el-col :span="8" class="text" style="text-align: right;">共&nbsp;{{ sale.refundOrderQuantity || 0 }}&nbsp;笔</el-col>
              </el-row>
            </div>
            <h3 style="color: #e6a23c; font-family: Arial; font-size: 36px; text-align: center; line-height: 120px;">
              ￥{{ sale.refundOrderAmount || 0.00 | toFixed2Filter }}
            </h3>
            <div class="text">
              <el-row>
                <el-col :span="12">抵扣优惠金额：&nbsp;￥{{ sale.refundOrderAmount - sale.outRefundAmount || 0.00 | toFixed2Filter }}</el-col>
                <el-col :span="12" style="text-align: right">实际退款金额：&nbsp;￥{{ sale.outRefundAmount || 0.00 | toFixed2Filter }}</el-col>
              </el-row>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-bottom: 20px">
        <el-col :span="12">
          <el-card class="box-card" shadow="hover">
            <div slot="header" class="clearfix">
              <h3>销额排行榜(TOP5)</h3>
            </div>
            <div v-show="revenueList.length > 0" v-for="(item, index) in revenueList"
                 :key="index"
                 class="item-list">
              <span>{{ item.title }}(￥{{ item.magnitude | toFixed2Filter }})</span>
              <el-progress
                  :text-inside="true"
                  :stroke-width="10"
                  :percentage="item.proportion"
                  status="warning"
              ></el-progress>
            </div>
            <el-empty v-show="revenueList.length == 0" description="暂无数据"></el-empty>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card class="box-card" shadow="hover">
            <div slot="header" class="clearfix">
              <h3>销量排行榜(TOP5)</h3>
            </div>
            <div v-show="topsList.length > 0" v-for="(item, index) in topsList" :key="index" class="item-list">
              <span>{{ item.title }}({{ item.magnitude }})</span>
              <el-progress
                  :text-inside="true"
                  :stroke-width="10"
                  :percentage="item.proportion"
                  status="warning"
              ></el-progress>
            </div>
            <el-empty v-show="topsList.length == 0" description="暂无数据"></el-empty>
          </el-card>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-card class="box-card" shadow="hover">
            <div slot="header" class="clearfix">
              <h3>支付类型占比</h3>
            </div>
            <div class="item-list" v-show="payList.length > 0" >
              <div id="main" ref="chart" style="height: 400px; width: 500px;"></div>
            </div>
            <el-empty v-show="payList.length == 0" description="暂无数据"></el-empty>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card class="box-card" shadow="hover">
            <div slot="header" class="clearfix">
              <h3>分类销量占比</h3>
            </div>
            <div class="item-list" v-show="List.length > 0">
              <div id="my" ref="chart1" style="height: 400px; width: 500px;"></div>
            </div>
            <el-empty v-show="List.length == 0" description="暂无数据"></el-empty>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import echarts from "echarts";
import cache from "@/utils/cache";
import {
  updateStatisticsData,
  getInfoList,
  getTopsList,
  getRevenueList,
  getPayList,
  getProportions,
} from "@/api/console";
export default {
  name: "Console",
  data() {
    return {
      storeInfo: cache.session.getJSON("storeInfo") || {},
      loading: false,
      radioButton:[{
          name: "今日",
          value: 0,
          buttonType:"warning",
        },
        {
          name: "昨日",
          value: 1,
          buttonType:"default",
        },
        {
          name: "本周",
          value: 2,
          buttonType:"default",
        },
        {
          name: "本月",
          value: 3,
          buttonType:"default",
        },
        {
          name: "本年",
          value: 4,
          buttonType:"default",
        },
        {
          name: "其他时间",
          value: 5,
          buttonType:"default",
        }],
      dataTime: [],
      startTime: "",
      endTime: "",
      sale: [],
      topsList: [],
      revenueList: [],
      option: {},
      payList: [],
      title: [],
      rule: {},
      List: [],
      ti: [],
      value1: [],
      showDate: false,
      gridData:[]
    };
  },
  created() {
    this.today();
  },
  mounted() {
    history.pushState(null, null, document.URL);
    window.addEventListener(
      "popstate",
      function (e) {
        history.pushState(null, null, document.URL);
      },
      false
    );
  },
  methods: {
    buttonEvent(event){
      if(event.value == 0){
        this.today();
      }else if(event.value == 1){
        this.yesterday();
      }else if(event.value == 2){
        //本周
        this.thisWeek();
      }else if(event.value == 3){
        //本月
        this.thisMonth();
      }else if(event.value == 4){
        //本年
        this.thisYear();
      }else if(event.value == 5){
        //其他
        this.$refs.datePicker.focus();
      }
      this.radioButton.forEach(item => {
        if(event.value == item.value){
          item.buttonType = "warning";
        }else{
          item.buttonType = "default";
        }
      });
    },
    today(){
      let today = new Date();
      this.startTime = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
      this.endTime = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
      this.dataTime = [new Date(this.startTime), new Date(this.endTime)];
      this.getData();
    },
    yesterday(){
      let yesterday = new Date();
      yesterday.setDate(new Date().getDate() -1);
      this.startTime = `${yesterday.getFullYear()}-${yesterday.getMonth() + 1}-${yesterday.getDate()}`;
      this.endTime = `${yesterday.getFullYear()}-${yesterday.getMonth() + 1}-${yesterday.getDate()}`;
      this.dataTime = [new Date(this.startTime), new Date(this.endTime)];
      this.getData();
    },
    thisWeek(){
      let today = new Date();
      let year = today.getFullYear();
      let month = today.getMonth() + 1;
      let date = today.getDate();
      let day = today.getDay() == 0 ? 7 : today.getDay(); //获取周几，0-6，0表示周日
      let startDate = new Date(year,month,date - day + 1);
      this.startTime = `${startDate.getFullYear()}-${startDate.getMonth()}-${startDate.getDate()}`;
      this.endTime = `${year}-${month}-${date}`;
      this.dataTime = [new Date(this.startTime), new Date(this.endTime)];
      this.getData();
    },
    thisMonth(){
      let today = new Date();
      let year = today.getFullYear();
      let month = today.getMonth() + 1;
      let date = today.getDate();
      let startDate = new Date(year,month,1);
      this.startTime = `${startDate.getFullYear()}-${startDate.getMonth()}-${startDate.getDate()}`;
      this.endTime = `${year}-${month}-${date}`;
      this.dataTime = [new Date(this.startTime), new Date(this.endTime)];
      this.getData();
    },
    thisYear(){
      let today = new Date();
      let year = today.getFullYear();
      let month = today.getMonth() + 1;
      let date = today.getDate();
      let startDate = new Date(year,1,1);
      this.startTime = `${startDate.getFullYear()}-${startDate.getMonth()}-${startDate.getDate()}`;
      this.endTime = `${year}-${month}-${date}`;
      this.dataTime = [new Date(this.startTime), new Date(this.endTime)];
      this.getData();
    },
    async updateStatisticsData(){
      this.loading = true;
      const response = await updateStatisticsData();
      if(response.code == 200){
        this.getData();
      }
      this.loading = false;
    },
    getData(){
      this.getSale();
      this.getTopList();
      this.getRevenueList();
      this.getPayList();
      this.getPropList();
    },
    async getSale() {
      const res = await getInfoList(this.getUrlData());
      if (res.code == 200) {
        this.sale = res.data;
        this.gridData = [];
        this.gridData.push({
          deal: this.sale.onLinePaymentQuantity,
          money: this.sale.onLinePaymentAmount,
          payment:'小程序支付'
        });
        this.gridData.push({
          deal: this.sale.scanCodePaymentQuantity,
          money: this.sale.scanCodePaymentAmount,
          payment:'付款码支付'
        });
        this.gridData.push({
          deal: this.sale.memberPaymentQuantity,
          money: this.sale.memberPaymentAmount,
          payment:'会员余额支付'
        });
        this.gridData.push({
          deal: this.sale.erweimaPaymentQuantity,
          money: this.sale.erweimaPaymentAmount,
          payment:'线下扫码支付'
        });
        this.gridData.push({
          deal: this.sale.cashPaymentQuantity,
          money: this.sale.cashPaymentAmount,
          payment:'现金支付'
        });
      } else {
        this.$message.warning("网络错误，请稍后重试");
      }
    },
    selectDate() {
      this.startTime = this.dataTime[0];
      this.endTime = this.dataTime[1];
      this.getData()
    },
    getUrlData() {
      return {
        storeId: this.storeInfo.id,
        startTime: this.startTime,
        endTime:this.endTime,
      };
    },
    async getTopList() {
      const res = await getTopsList(this.getUrlData());
      // console.log(res);
      if (res.code == 200) {
        this.topsList = res.data;
      } else {
        this.$message.warning("网络错误，请稍后重试");
      }
    },
    async getRevenueList() {
      const res = await getRevenueList(this.getUrlData());
      // console.log(res);
      if (res.code == 200) {
        this.revenueList = res.data;
      } else {
        this.$message.warning("网络错误，请稍后重试");
      }
    },
    async getPayList() {
      const res = await getPayList(this.getUrlData());
      if (res.code == 200) {
        let List = res.data;
        this.payList = List.map((item) => {
          return {
            value: item.magnitude,
            name: item.title,
          };
        });
        this.payList.forEach((item) => {
          this.title.push(item.name);
        });
        const myChart = echarts.init(document.getElementById("main"));
        this.option = {
          title: {
            left: "center",
          },
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b}: {c} ({d}%)",
          },
          legend: {
            orient: "vertical",
            left: 3,
            data: this.title,
          },
          series: [
            {
              name: "支付类型占比",
              type: "pie",
              radius: ["40%", "70%"],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: "center",
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: "28",
                  fontWeight: "bold",
                },
              },
              labelLine: {
                show: false,
              },
              data: this.payList,
            },
          ],
        };
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(this.option);
      } else {
        this.$message.warning("网络错误，请稍后重试");
      }
    },
    async getPropList() {
      const res = await getProportions(this.getUrlData());
      // console.log(res);
      if (res.code == 200) {
        let Lists = res.data;
        // console.log(res.data);
        this.List = Lists.map((item) => {
          return {
            value: item.magnitude,
            name: item.title,
          };
        });
        // console.log(this.List);
        this.List.forEach((item) => {
          this.ti.push(item.name);
        });
        // console.log(this.ti);
        const myChart2 = echarts.init(document.getElementById("my"));
        this.rule = {
          title: {
            left: "center",
          },
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b}: {c} ({d}%)",
          },
          legend: {
            orient: "vertical",
            left: 3,
            data: this.ti,
          },
          series: [
            {
              name: "销量占比",
              type: "pie",
              radius: ["40%", "70%"],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: "center",
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: "28",
                  fontWeight: "bold",
                },
              },
              labelLine: {
                show: false,
              },
              data: this.List,
            },
          ],
        };
        // 使用刚指定的配置项和数据显示图表。
        myChart2.setOption(this.rule);
      } else {
        this.$message.warning("网络错误，请稍后重试");
      }
    }
  },
};
</script>

<style scoped>
.pickerTime{
  opacity: 0;
  position: absolute;
  right: 0px;
  top: 30px;
  width: 0px;
  height: 0px;
}

::v-deep .el-progress-bar__innerText {
  display: none !important;
}
.item-list {
  padding: 10px 0px;
}
.box {
  position: relative;
  margin: 10px;
  padding-bottom: 30px;
}
.block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
h1,h2,h3,h4,h5{
  padding: 0px;
  margin: 0px;
}
.text{
  color:#909399;
  font-size: 16px;
}
::v-deep .el-input__inner {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
</style>
